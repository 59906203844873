import React from 'react';
import axios from 'axios';
import { useNavigation } from '../../utilities/NavigationContext';
import { withRouter } from 'react-router-dom';
import { ChatBubbleLeftIcon, HandThumbUpIcon, UserCircleIcon } from '@heroicons/react/20/solid';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString(); // Months are zero-based
    let year = date.getFullYear();
  
    // Add leading zero to day and month if they are less than 10
    day = day.length < 2 ? '0' + day : day;
    month = month.length < 2 ? '0' + month : month;
  
    return `${day}/${month}/${year}`;
  };

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class Discussion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                comment: "",
                replyTo: "",
                subReplyTo: ""
            },
            commentData : [],
            isSubmitted: false,
            activeReplyId: null,
            visibleReplies: new Set(),
            discussId: '',
            activeMenuCommentId: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);

    }

    componentDidMount() {
        this.fetchCommentData();
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');
        this.setState({ discussId: id });
    }

    componentDidUpdate(prevState) {

        if (this.state.isSubmitted && !prevState.isSubmitted) {
            this.fetchCommentData();
            this.setState({ isSubmitted: false });
        }
    }


    fetchCommentData = async () => {
        try {
          const response = await axios.get(`${EndPoint}/comments`, {
            withCredentials: true,
          });
          this.setState({ commentData: response.data });
        } catch (error) {
          console.error('Error fetching comments:', error);
          // Handle errors here
        }
      };

    // open settings menu
    handleMenuClick(e, commentId) {
        e.stopPropagation();

        this.setState(prevState => ({
            activeMenuCommentId: prevState.activeMenuCommentId === commentId ? null : commentId
        }));
    }  

    handleChange(event) {
        const { name, value } = event.target;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    }

    handleSubmit(type, postId, replyId) {
        const { formData } = this.state;

        if (type === 'reply') {
            var updatedFormData = {...formData, replyTo: postId, subReplyTo: '', like: 0};
        } else {
            updatedFormData = {...formData, replyTo: postId, subReplyTo: replyId, like: 0};
        }
        axios.post(`${EndPoint}/comments`, updatedFormData, {withCredentials: true})
        .then(response => {
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            this.handleClear();
            this.setState({ isSubmitted: true, activeReplyId: null });
        })
        .catch(error => {
            toast.error(error.responsse.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        });
    }

    handleDelete(e, id) {
        e.stopPropagation();
        axios.delete(`${EndPoint}/comments/${id}`, {withCredentials: true})
        .then(response => {
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            this.handleClear();
            this.fetchCommentData();
        })
        .catch(error => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        });

    }
    
    handleClear() {
        this.setState({
            formData: {
                comment: "", 
                replyTo: "",
                subRepltyTo : "",
                like : 0
            }
        });
    }

    handleLike = async (e, id) => {

        e.stopPropagation();
        try {
            const response = await axios.post(`${EndPoint}/comments/like`, {id: id}, {
              withCredentials: true,
            });
            this.fetchCommentData();
            toast.success(`You like this comment. Thank you !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
          } catch (error) {
            console.error('Error fetching comments:', error);
            toast.error(`You already like this comment !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
          }
    }

    renderSubReplies(id) {
        var subReplies = this.state.commentData;
        return subReplies.map((item, index) => {
            if (item.subReplyTo && item.subReplyTo === id) {
                return (
                    <div className='flex flex-col'>
                    <div className='ml-32 p-4  m-1 w-[98%] flex flex-row gap-2' key={index}>
                        <div className='text-xs'>
                            <UserCircleIcon className='w-14 h-14 text-slate-300 hover:text-slate-500' />
                        </div>
                        <div className='text-xs'>
                            <p className='text-sm'>{item.userName}</p>
                            <p className='text-xs mb-1'>{item.userType}</p>
                            <p className='text-md'>{item.comment}</p>
                            <p className='text-xs my-1'>{formatDate(item.date)}</p>
                            <p className='flex flex-row gap-2 relative'>
                                <div className='flex flex-row'>
                                    <div className='p-1 mt-2'>{item.like}</div>
                                    <div><button className='p-1 text-red-800 hover:text-sky-800 font-semibold mt-2' onClick={(e) => this.handleLike(e, item._id)}>Like</button></div>
                                </div>
                          
                                <div className='flex flex-row'><button className='text-sm px-3 py-1  hover:bg-slate-200 rounded' onClick={(e) => this.handleMenuClick(e, item._id)}>...</button></div>
                                {
                                    this.state.activeMenuCommentId === item._id &&
                                    <div className="submenu absolute w-24 rounded p-1 flex flex-col gap-1 mt-10 ml-28 z-10 bg-slate-100">
                                        <button className="submenu-option text-left p-1 hover:bg-red-200">Report Post</button>
                                        <button className="submenu-option text-left p-1 hover:bg-red-200" onClick={(e) => this.handleDelete(e, item._id)}>Delete Post</button>
                                    </div>
                                }
                            </p>
                        </div> 
                    </div>
                        <div className='col-span-6'>{this.renderForm(item._id)}</div>
                    </div>
                )
            }
        })
    }


    renderReplies(id) {
    
        var replies = this.state.commentData;
        var filteredReplies = replies.filter(item => item.replyTo !== "" && !item.subReplyTo);
        var subReplies = replies.filter(item => item.subReplyto === id);
        var numberReplies = subReplies.length;
        return filteredReplies.map((item, index) => {
      
            if (item.replyTo && item.replyTo === id ) {
                return (
                    <div className='flex flex-col'>
                    <div className='ml-16 p-4  m-1 w-[98%] flex flex-row gap-2' key={index}>
                        <div className='text-xs'>
                            <UserCircleIcon className='w-14 h-14 text-slate-300 hover:text-slate-500' />
                        </div>
                        <div className='text-xs col-span-10'>
                            <p className='text-sm'>{item.userName}</p>
                            <p className='text-xs mb-1'>{item.userType}</p>
                            <p className='text-md'>{item.comment}</p>
                            <p className='text-xs my-1'>{formatDate(item.date)}</p>
                            <p className='flex flex-row gap-2 relative'>
                                <div className='flex flex-row'>
                                    <div className='p-1 mt-2'>{item.like}</div>
                                    <div><button className='p-1 text-red-800 hover:text-sky-800 font-semibold mt-2' onClick={(e) => this.handleLike(e, item._id)}>Like</button></div>
                                </div>
                                <div className='flex flex-row'>
                                    <div className='p-1 mt-2'>{numberReplies}</div>
                                    <div><button className='p-1 text-red-800 hover:text-sky-800 font-semibold mt-2' onClick={() => this.handleReplyClick(item._id)}>Reply</button></div>
                                </div>
                                <div className='flex flex-row'><button className='text-sm px-3 py-1  hover:bg-slate-200 rounded' onClick={(e) => this.handleMenuClick(e, item._id)}>...</button></div>
                                {
                                    this.state.activeMenuCommentId === item._id &&
                                    <div className="submenu absolute w-24 rounded p-1 flex flex-col gap-1 mt-10 ml-28 z-10 bg-slate-100">
                                        <button className="submenu-option text-left p-1 hover:bg-red-200">Report Post</button>
                                        <button className="submenu-option text-left p-1 hover:bg-red-200" onClick={(e) => this.handleDelete(e, item._id)}>Delete Post</button>
                                    </div>
                                }
                            </p>
                        </div>
                        
                    </div>
                    <div className='col-span-6 ml-36'>{this.renderForm(item._id, 'subReply', id, item._id)}</div>
                    {this.renderSubReplies(item._id)}
                    </div>
                )
            }
        })
    }

    renderAllComments() {
        var comments = this.state.commentData;
        var currentComment = comments.filter(item => item._id === this.state.discussId);
        var relatedReplies = comments.filter(item => item.replyTo === this.state.discussId);
        var allComments = currentComment.concat(relatedReplies);
        var numberReplies = relatedReplies.length;
    
        return allComments.map((item, index) => {
            if (!item.replyTo) {
                return (
                    <div className='flex flex-col'>
                    <div className='rounded p-4 m-1 w-[90%] flex flex-row gap-2' key={index}>
                        <div className='text-xs'>
                            <UserCircleIcon className='w-14 h-14 text-slate-300 hover:text-slate-500' />
                        </div>
                        <div className='text-sm col-span-10'>
                            <p className='text-sm'>{item.userName}</p>
                            <p className='text-xs mb-1'>{item.userType}</p>
                            <p className='text-md'>{item.comment}</p>
                            <p className='text-xs my-1'>{formatDate(item.date)}</p>
                            <p className='flex flex-row gap-2 relative'>
                                <div className='flex flex-row'>
                                    <div className='p-1 mt-2'>{item.like}</div>
                                    <div><button className='p-1 text-red-800 hover:text-sky-800 font-semibold mt-2' onClick={(e) => this.handleLike(e, item._id)}>Like</button></div>
                                </div>
                                <div className='flex flex-row'>
                                    <div className='p-1 mt-2'>{numberReplies}</div>
                                    <div><button className='p-1 text-red-800 hover:text-sky-800 font-semibold mt-2' onClick={() => this.handleReplyClick(item._id)}>Reply</button></div>
                                </div>
                                <div className='flex flex-row'><button className='text-sm px-3 py-1  hover:bg-slate-200 rounded' onClick={(e) => this.handleMenuClick(e, item._id)}>...</button></div>
                                {
                                    this.state.activeMenuCommentId === item._id &&
                                    <div className="submenu absolute w-24 rounded p-1 flex flex-col gap-1 mt-10 ml-28 z-10 bg-slate-100">
                                        <button className="submenu-option text-left p-1 hover:bg-red-200">Report Post</button>
                                        <button className="submenu-option text-left p-1 hover:bg-red-200" onClick={(e) => this.handleDelete(e, item._id)}>Delete Post</button>
                                    </div>
                                }
                            </p>
                            
                        </div>
                    </div>
                    <div className='col-span-6 ml-20'>{this.renderForm(item._id, 'reply', item._id, "")}</div>
                    <div className='mb-4'>{this.renderReplies(item._id)}</div>
                    </div>
                )
            } 
        
        })
    }

    handleReplyClick = (id) => {
        this.setState({ activeReplyId: id });
    }

    handleCancel() {
        this.setState({activeReplyId: null})
    }


    renderForm(id, type, postId, replyId) {
        if (this.state.activeReplyId !== id) return null;

        var formData = this.state.formData;
        return (
            <div>
            <textarea
            name="comment"
            value={formData.comment}
            onChange={this.handleChange}
            className="p-1 mt-2 mb-0 text-sm h-[50px] w-full resize-none rounded border-slate-200"
            />
             <button className='bg-sky-600 hover:bg-sky-700 text-white ml-2 p-2 w-32 rounded shadow-lg text-sm float-right' onClick={() => this.handleSubmit (type, postId, replyId)}>Submit</button>
             <button className='bg-red-600 hover:bg-red-700 text-white p-2 w-32 rounded shadow-lg text-sm float-right' onClick={this.handleCancel}>Cancel</button>

           
            </div>
        )
    }

    render() {
        return (
            
            <div className="mt-20 mx-52 xs:mx-4 sm:mx-10 md:mx-14">

                <div className='h-[700px] overflow-y-auto overflow-x-hidden p-4'>{this.renderAllComments()}</div>

                <ToastContainer />
            </div> 
        )
    }
} 


const DiscussionWithNavigation = (props) => {
    const navigate = useNavigation(); // Use the useNavigation hook here
    return <Discussion {...props} navigate={navigate} />;
};

export default DiscussionWithNavigation;