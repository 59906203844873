import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import Tools from './components/Tools';
import Login from './components/Login'; 
import Register from './components/Register'; 
import ForgotPassword from './components/AccountComponents/ForgotPassword';
import UpdatePassword from './components/AccountComponents/UpdatePassword';

import Navigation from './components/Navigation';

import Home from './components/Home';
import Interview from './components/Interview';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NavigationProvider } from './utilities/NavigationContext';
import { AuthProvider } from './utilities/AuthContext';
import { SharedFunctionProvider } from './utilities/SharedFunctionContext';

import PrivateRoutes from './utilities/PrivateRoutes';
import Explore from './components/Explore';
import LocalAI from './components/LocalAI';
import InterviewProject from './components/InterviewProject';
import ClipCopy from './components/ClipCopy';
import Log from './components/Log';

import Discussion from './components/CommunityComponents/Discussion';
import Community from './components/MainComponents/Community';

// Transaction
import Usage from './components/AccountComponents/Usage';
import Pricing from './components/TransactionComponents/Pricing';
import SuccessfulPayment from './components/TransactionComponents/SuccessPayment';
import FailedPayment from './components/TransactionComponents/FailedPayment';
import Transaction from './components/AccountComponents/Transaction';

// rewrite 
import Master from './components/Master';

class App extends React.Component {

  render() {

    return (
      <Router>
        <AuthProvider>
        <NavigationProvider>
        <SharedFunctionProvider>
            <ToastContainer />
            <Navigation />

            <Routes>
             
              <Route path="/" element={<Home />} />
              <Route element={<PrivateRoutes />}>

                  <Route path="/tools" element={<Tools />} />
                  <Route path="/master" element={<Master />} />

                  <Route path="/updatepassword" element={<UpdatePassword />} />

                  <Route path="/usage" element={<Usage />} />
                  <Route path="/log" element={<Log />} />

        
              </Route>       
              <Route path="/localai" element={<LocalAI />} />
              <Route path="/explore" element={<Explore />} />

              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />

              <Route path="/clipcopy" element={<ClipCopy />} />
              <Route path="/interview" element={<Interview />} />
              <Route path="/project" element={<InterviewProject />} />

              <Route path="/community" element={<Community />} />
              <Route path="/discussion" element={<Discussion />} />

              <Route path="/transaction" element={<Transaction />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/payment-success" element={<SuccessfulPayment />} />
              <Route path="/payment-failed" element={<FailedPayment />} />
              
      

            </Routes>
        </SharedFunctionProvider>
        </NavigationProvider>
        </AuthProvider>
      </Router>
    );
  }
}

export default App;
