
import axios from 'axios';


//   const chunkSize = chunkSizeMB * 1024 * 1024; // Convert MB to bytes
//   const totalChunks = Math.ceil(blob.size / chunkSize);

//   let start = 0;
//   const chunks = [];

//   for (let i = 0; i < totalChunks; i++) {
//     const end = Math.min(start + chunkSize, blob.size);
//     const chunk = blob.slice(start, end);
//     console.log(`Chunk ${i + 1}: size = ${chunk.size} bytes`);
//     chunks.push(chunk);
//     start = end;
//   }

//   return chunks;
// };

// export const transcribeAudio = async (blob) => {
//   const chunks = splitAudioBlob(blob, 25); 
//   const transcriptions = [];

//   for (const chunk of chunks) {
//     const formData = new FormData();
//     formData.append('file', chunk);
//     formData.append('model', 'whisper-1');
//     formData.append('language', 'en');

//     try {
//       const transcribeRes = await axios.post(
//         'https://api.openai.com/v1/audio/transcriptions',
//         formData,
//         {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//             Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
//           },
//         }
//       );
//       transcriptions.push(transcribeRes.data.text);
//     } catch (error) {
//       console.error('Error transcribing audio chunk:', error);
//     }
//   }

//   // Combine all transcriptions
//   return transcriptions.join(' ');
// };

export const transcribeAudio = async (blob) => {
  if (!blob) {
    console.error('No audio to transcribe.');
    return;
  }

  try {
    const formData = new FormData();
    formData.append('file', blob);
    formData.append('model', 'whisper-1');
    formData.append('language', 'en');
    formData.append('temperature', '0.1');  

    const transcribeRes = await axios.post(
      'https://api.openai.com/v1/audio/transcriptions',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );
    return transcribeRes.data.text;
  } catch (error) {
    console.error('Error transcribing audio:', error);
    return 'Error transcribing audio.';
  }
};

export const LLMTextToJSON = async (processedInput) => {
  try {
    const res = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4-turbo',
        messages: [{ role: 'user', content: processedInput }],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;

    // Checking for JSON content and cleaning up formatting artifacts
    if (messageContent.includes('```json')) {
      messageContent = messageContent.replace(/```json/g, '').trim();
    }

    if (messageContent.includes('```')) {
      messageContent = messageContent.replace(/```/g, '').trim();
    }

    if (messageContent.includes('...')) {
      messageContent = messageContent.replace(/.../g, '').trim();
    }


    // Handling the prefix "Here is the report in JSON format:" or similar
    const jsonPrefix = 'Here is the report in JSON format:';
    if (messageContent.startsWith(jsonPrefix)) {
      messageContent = messageContent.substring(jsonPrefix.length).trim();
    }

    // Attempt to parse the cleaned message content
    const jsonResponse = JSON.parse(messageContent);
    return jsonResponse;

  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // Return null or handle the error as you see fit
  }
};


export const LLMTextToText = async (processedInput) => {
  try {
    const res = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4-turbo',
        messages: [{ role: 'user', content: processedInput }],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;

    return messageContent;
  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // or you can return an empty string, or rethrow the error
  }
};

export const writeNotes = async (instruction, input) => {
  var respond = await LLMTextToText(instruction);
  return respond;
}

export const sortingText = async (input) => {
    var respond = await LLMTextToJSON(input);
    return respond;
}

export const AITextToObject = async (instruction, input) => {

  var processedInput = `${instruction}\nUser: ${input}}`;

  try {
    const res = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4-turbo',
        messages: [{ role: 'user', content: processedInput }],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;

    // Checking for JSON content and cleaning up formatting artifacts
    if (messageContent.includes('```json')) {
      messageContent = messageContent.replace(/```json/g, '').trim();
    }

    if (messageContent.includes('```')) {
      messageContent = messageContent.replace(/```/g, '').trim();
    }

    if (messageContent.includes('...')) {
      messageContent = messageContent.replace(/.../g, '').trim();
    }
    console.log(messageContent)

    // Handling the prefix "Here is the report in JSON format:" or similar
    const jsonPrefix = 'Here is the report in JSON format:';
    if (messageContent.startsWith(jsonPrefix)) {
      messageContent = messageContent.substring(jsonPrefix.length).trim();
    }
    // Attempt to parse the cleaned message content
    const jsonResponse = JSON.parse(messageContent);
    return jsonResponse;

  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // Return null or handle the error as you see fit
  }
};

export const AITextToText = async (instruction, input, initData) => {

  const messages = [
    { role: 'system', content: instruction }
  ];

  if (input !== "") {
    messages.push({ role: 'user', content: input });
  } else {
    return;
  }

  if (initData) {
    messages.push({ role: 'user', content: JSON.stringify(initData) });
  }



  try {
    const res = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini',
        messages: messages,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;

    return messageContent;
  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // or you can return an empty string, or rethrow the error
  }
};

export const AITextToObjectwithData = async (instruction, input, initData) => {

  // var processedInput = `${instruction}\nUser: ${input}}`;

  try {
    const res = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-3.5-turbo',
        messages: [
          { role : 'system', content: instruction},
          { role: 'user', content: input },
          { role: 'user', content: JSON.stringify(initData) }
        ],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;

    // Checking for JSON content and cleaning up formatting artifacts
    if (messageContent.includes('```json')) {
      messageContent = messageContent.replace(/```json/g, '').trim();
    }

    if (messageContent.includes('```')) {
      messageContent = messageContent.replace(/```/g, '').trim();
    }

    if (messageContent.includes('...')) {
      messageContent = messageContent.replace(/.../g, '').trim();
    }

    // Handling the prefix "Here is the report in JSON format:" or similar
    const jsonPrefix = 'Here is the report in JSON format:';
    if (messageContent.startsWith(jsonPrefix)) {
      messageContent = messageContent.substring(jsonPrefix.length).trim();
    }
    // Attempt to parse the cleaned message content
    const jsonResponse = JSON.parse(messageContent);
    return jsonResponse;

  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // Return null or handle the error as you see fit
  }
};

export const AITextToObjectwithDataAndRef = async (instruction, input, initData, referrences) => {
  try {
    // Create message array conditionally based on the existence of `referrences`
    const messages = [
      { role: 'system', content: instruction }
    ];

    if (input !== "") {
      messages.push({ role: 'user', content: input });
    }

    if (initData) {
      messages.push({ role: 'user', content: JSON.stringify(initData) });
    }

    // Add `referrences` only if it exists
    if (referrences) {
      messages.push({ role: 'user', content: JSON.stringify(referrences) });
    }
    const res = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini',
        messages: messages,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;
    
    const jsonPrefix = 'Here is the report in JSON format:';

    // Comprehensive cleanup of JSON artifacts
    messageContent = messageContent
      .replace(/```json/g, '')
      .replace(/```/g, '')
      .replace(/\.\.\./g, '')
      .replace(jsonPrefix, '') // Removes the specific prefix text if it appears
      .trim();

    // Attempt to parse the cleaned message content
    const jsonResponse = JSON.parse(messageContent);
    return jsonResponse;

  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // Return null or handle the error as appropriate
  }
};
