import React, { Component } from 'react';
import axios from 'axios';


const EndPoint = process.env.REACT_APP_API_ENDPOINT;

// Plan details
const plans = [
  {
    title: "Starter Care Plan",
    description: "Perfect for small-scale care needs. Manage up to a few patients with ease using 100 tokens.",
    price: "$20",
    qty: "100", 
    discount : ""
  },
  {
    title: "Professional Care Plan",
    description: "Ideal for moderate patient loads. Use 500 tokens to streamline workflows for growing practices.",
    price: "$90",
    qty: "500",
    discount : "10%"
  },
  {
    title: "Comprehensive Care Plan",
    description: "Designed for high-volume care needs. Manage a large patient base efficiently with 1,000 tokens.",
    price: "$180",
    qty: "1,000",
    discount: "10%"
  },
];

class Pricing extends Component {
  state = {
    plans: [], // Initialize state to store fetched data
  };

  componentDidMount() {
    axios
      .get(`${EndPoint}/transaction/all-products`)
      .then((response) => {
        this.setState({ plans: response.data });
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }

  // Handle Buy button click
  handleBuyClick = (priceId) => {
    console.log("Buying product with ID:", priceId);
    // Add your logic here for handling the purchase
    axios
    .post(
      `${EndPoint}/transaction/create-checkout-session`,
      { priceId }, // Send priceId in the request body
      { withCredentials: true } // Include credentials (cookies) with the request
    )
    .then((response) => {
      console.log("Checkout session created:", response.data);
      // Handle the response, e.g., redirect to the checkout URL
      console.log(response.data.url)
      if (response.data.url) {
        window.location.href = response.data.url; // Redirect to checkout page
      }
    })
    .catch((error) => {
      console.error("Error creating checkout session:", error);
    });
  };
  


  render() {
    const { plans } = this.state;

    return (
      <div className="min-h-screen bg-gray-100 flex flex-col items-center">
        <h1 className="text-5xl font-bold text-gray-800 mt-20 mb-12">Pricing</h1>
        <div className="flex justify-center gap-8">
          {plans.map((plan) => (
            <div key={plan.productId} className="bg-white shadow-lg p-6 rounded-lg w-1/4">
              <h2 className="text-3xl font-bold text-gray-700 m-4 text-center">{plan.name}</h2>
              {/* <p className="text-3xl font-bold text-sky-800 my-4 mx-20 bg-slate-200 rounded shadow text-center">{plan.qty} tokens</p> */}
              <p className="text-lg text-gray-800 mb-4 text-center">{plan.description}</p>
              <p className='text-xl font-semibold pt-4 mb-0 text-center'>Features</p>
              <div className='p-6 text-md'>
                {/* <p className='bg-red-700 text-white p-2 m-2 rounded shadow'>Up to {plan.qty} Consultations</p> */}
                <p className='bg-slate-100 p-2 m-2 rounded shadow'>Chronic Disease Planning</p>
                <p className='bg-slate-100 p-2 m-2 rounded shadow'>Team Care Arrangement</p>
                <p className='bg-slate-100 p-2 m-2 rounded shadow'>Mental Health Care Plan</p>
                <p className='bg-slate-100 p-2 m-2 rounded shadow'>Health Assessment</p>
                <p className='bg-slate-100 p-2 m-2 rounded shadow'>Chat Record</p>
                <p className='bg-slate-100 p-2 m-2 rounded shadow'>Various Tools</p>
                <p className='bg-slate-100 p-2 m-2 rounded shadow'>Free updates</p>
              </div>
              <p className="mb-6 text-center"><span className='text-2xl font-semibold'>AUD</span> <span className="text-sky-600 font-bold text-7xl">{plan.price.amount}</span></p>
              <button 
              className="bg-sky-700 hover:bg-sky-800 text-white font-semibold py-4 px-4 rounded w-full"
              onClick={() => this.handleBuyClick(plan.price.priceId)}
              >
                Buy
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Pricing;
