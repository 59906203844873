import React, { Component } from 'react';

class SuccessfulPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credits: 0, // Initial credits in the account
    };
  }

  componentDidMount() {
    // Simulate adding credits after successful payment
    const addedCredits = this.props.addedCredits || 100; // Default to 100 if not provided
    this.setState({ credits: this.state.credits + addedCredits });
  }

  render() {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-green-100">
        <h1 className="text-2xl font-bold text-green-700 mb-4">Payment Successful!</h1>
        <p className="text-gray-700 mb-6">
          Your account has been credited with <span className="font-bold">{this.state.credits} tokens</span>.
        </p>
        <button
          onClick={() => alert('Returning to dashboard')}
          className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
        >
          Return to Dashboard
        </button>
      </div>
    );
  }
}

export default SuccessfulPayment;
