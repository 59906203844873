import React from 'react';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Planning from '../SharedComponents/Planning';



function generateId() {
    return Math.random().toString(36).substr(2, 9);
}


class HealthAssessment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            referrences: [],
            dashboard: [],
            formData: this.initializeFormData(props.patientData),
            adminData: this.props.adminData,
            isEditing: false,
            //active tab
            activeTab: 'background',
            //helper
            isHelper: true
        };


    }

    // Initialize formData to ensure healthassessment contains all necessary fields
    initializeFormData = (patientData) => {
        const defaultHealthAssessment = {
            medicalHistory: '',
            pastMedical: '',
            allergies: '',
            familyHistory: '',
            alcohol: '',
            smoking: '',
            substance: '',
            medications: '',
            medicationArrangement: '',
            vision: '',
            hearing: '',
            fallAndHomeSafety: '',
            skin: '',
            feet: '',
            nutrition: '',
            oralHealth: '',
            cognition: '',
            mentalHealth: '',
            urineContinence: '',
            faecalContinence: '',
            mobility: '',
            activity: '',
            social: '',
            housing: '',
            legal: '',
            finance: '',
            driving: '',
            advancedCareDirective: '',
            services: '',
            examination: '',
            surveillance: '',
            vaccination: '',
            date: '', // Format this appropriately
        };

        // Ensure healthassessment has all the necessary fields
        return {
            ...patientData,
            healthassessment: {
                ...defaultHealthAssessment,
                ...patientData.healthassessment,
            },
            planning: patientData.planning || [], // Use patientData.planning directly
            hareviews: patientData.hareviews || []
        };
    };
    
    // componentDidMount() {
    //     const encryptedAdmin = localStorage.getItem('admin');
    //     if (encryptedAdmin) {
    //         const bytes = CryptoJS.AES.decrypt(encryptedAdmin, secretStorage);
    //         const adminData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //         const referrences = adminData.contacts;
    //         const dashboard = adminData.dashboard;
    //         this.setState({ referrences: referrences, dashboard: dashboard });
    //     }
    // }

    componentDidUpdate(prevProps) {
        if (prevProps.patientData !== this.props.patientData) {
            this.setState({
                formData: this.initializeFormData(this.props.patientData),
            });
        }
        if (prevProps.adminData !== this.props.adminData) {
            this.setState({ amindData: this.props.adminData });
        }
    }
    // Function to switch tabs
    switchTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    //Edit form functions
    handleToggleEdit = () => {
        this.setState((prevState) => {
            // If we're switching from editing to saving, update the parent data
            if (prevState.isEditing) {
                this.updatePatientData(); // Call updatePatientData to send the updated formData to the parent
            }
    
            return {
                isEditing: !prevState.isEditing, // Toggle editing state
            };
        });
    };
    

    handleInputChange = (key, value) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                healthassessment: {
                    ...prevState.formData.healthassessment,
                    [key]: value
                }
            }
        }));
    };
    

    // Function to update problems from child component
    handleProblemsUpdate = (updatedProblems) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                planning: updatedProblems,
            },
        }), this.updatePatientData);
    };


    updatePatientData = () => {
        this.props.updatePatientData(this.state.formData)
    };

    renderBackground() {
        const { isEditing } = this.state;
        const {healthassessment} = this.state.formData
        const fieldData = [
            { name: 'medicalHistory', label: 'Medical History' },
            { name: 'pastMedical', label: 'Past Medical History' },
            { name: 'allergies', label: 'Allergies' },
            { name: 'familyHistory', label: 'Family History' },
            { name: 'alcohol', label: 'Alcohol Use' },
            { name: 'smoking', label: 'Smoking' },
            { name: 'substance', label: 'Substance Use'},
            { name: 'medications', label: 'Medications' },
            { name: 'medicationArrangement', label: 'Medication Arrangement' },
            { name: 'vision', label: 'Vision' },
            { name: 'hearing', label: 'Hearing' },
            { name: 'fallAndHomeSafety', label: 'Fall and Home Safety' },
            { name: 'skin', label: 'Skin' },
            { name: 'feet', label: 'Feet' },
            { name: 'nutrition', label: 'Nutrition' },
            { name: 'oralHealth', label: 'Oral Health' },
            { name: 'cognition', label: 'Cognition' },
            { name: 'mentalHealth', label: 'Mental Health' },
            { name: 'urineContinence', label: 'Urinary Continence' },
            { name: 'faecalContinence', label: 'Bowel Continence' },
            { name: 'mobility', label: 'Mobility' },
            { name: 'activity', label: 'Activity' },
            { name: 'social', label: 'Social' },
            { name: 'housing', label: 'Accomodation'},
            { name: 'legal', label: 'Legal' },
            { name: 'finance', label: 'Finance' },
            { name: 'driving', label: 'Driving' },
            { name: 'advancedCareDirective', label: 'Advanced Care Directive' },
            { name: 'services', label: 'Services' },
            { name: 'examination', label: 'Examination' },
            { name: 'surveillance', label: 'Surveillance' },
            { name: 'vaccination', label: 'Vaccinations' },
            { name: 'date', label: 'Date completed' },

        ];

        return (
            <div className='col-span-6 rounded shadow-md'>
                
                {/* Helper */}
                {this.state.isHelper && (
                <div className='fixed -mt-60 right-4 w-[400px] h-auto bg-yellow-100 shadow rounded-md p-4' onClick={()=> this.setState({isHelper:false})}>
                    <p className='text-lg font-semibold text-red-700 text-center'>No Entry yet</p>
                    {fieldData.map((field) => {
                        if (healthassessment[field.name] === "") {
                            return <div key={field.name} className='p-1'>- {field.label}</div>;
                        }
                    })}
            
                </div>
                )}

            <div className='bg-slate-100 m-4'>
            <div className='flex justify-between items-center px-6 py-2'>
                <h2 className='text-lg font-bold text-center'>Assessment</h2>
                <button
                    onClick={this.handleToggleEdit}
                    className={`px-4 py-2 rounded text-white ${isEditing ? 'bg-sky-600 hover:bg-sky-700' : 'bg-yellow-500 hover:bg-yellow-600'}`}
                >
                    {isEditing ? 'Save' : 'Edit'}
                </button>
            </div>
            <div className='mt-4 grid grid-cols-2 gap-4 m-4 p-2'>
                {fieldData.map((field) => (
                    <div key={field.name}>
                        <h3 className='text-sm font-semibold'>{field.label}</h3>


                        { field.name === 'date' ? (
                            <input
                                type="date"
                                className='w-full text-black w-[90%] text-sm border-gray-200'
                                value={this.state.formData.healthassessment[field.name]}
                                readOnly={!isEditing}
                                onChange={(e) => this.handleInputChange(field.name.target.value)}
                                disabled={!isEditing}
                            />
                        ) : (
                            <textarea
                                className='w-full h-32 resize-none text-black w-[90%] text-sm border-gray-200'
                                value={this.state.formData.healthassessment[field.name]}
                                readOnly={!isEditing}
                                onChange={(e) => this.handleInputChange(field.name, e.target.value)}
                                disabled={!isEditing}
                            />
                        )}

                    </div>
                ))}
            </div>
            </div>

        </div>
        )
    }

    render() {

        const { activeTab, formData } = this.state;
   
        return (
            <div  className="col-span-6 grid-rows-4 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-3" style={{ gridTemplateRows: 'minmax(12px, auto) minmax(12px, auto) minmax(320px, auto)' }}>
               
                <div className='text-center col-span-6 text-3xl font-bold py-4 text-sky-900'>Health Assessment</div>
                <div className="tabs col-span-6 my-4 flex justify-center">
                  
                    <div className='bg-slate-100 rounded w-92'>
                    <button
                    className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTab === 'background' ? 'bg-sky-300' : 'bg-slate-200'}`}
                    onClick={() => this.switchTab('background')}
                    >
                        Background
                    </button>
                    <button
                    className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTab === 'planning' ? 'bg-sky-300' : 'bg-slate-200'}`}
                    onClick={() => this.switchTab('planning')}
                    >
                        Planning
                    </button>
                 
                    </div>
                </div>
                <div className='tabContent col-span-6'>
                
                    {activeTab === 'background' &&  this.renderBackground()}
                    {activeTab === 'planning' && formData.planning && (
                    <Planning problems={formData.planning} onProblemsUpdate={this.handleProblemsUpdate}  onVoiceInput={this.AITranscription} category='All' />
                    )}
          
                
                </div>
                <ToastContainer />
            </div>
        );
    }

}

    
export default HealthAssessment;