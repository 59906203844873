import React from 'react';

const Tooltip = ({ text }) => {
  return (
    <div className="absolute top-[-24px] left-10 w-32 text-center p-2 bg-gray-700 text-white text-sm rounded-md shadow-lg z-50">
      {text}
    </div>
  );
};

export default Tooltip;
