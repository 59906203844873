import React, { createContext, useContext, useState } from 'react';

export const SharedFunctionContext = createContext();

export const useSharedFunction = () => useContext(SharedFunctionContext);

export const SharedFunctionProvider = ({ children }) => {
  const [data, setData] = useState(null);

  // Function to be shared
  const sharedFunction = (input) => {
    console.log('Function called with:', input);
    setData(input);
  };

  return (
    <SharedFunctionContext.Provider value={{ sharedFunction, data }}>
      {children}
    </SharedFunctionContext.Provider>
  );
};
