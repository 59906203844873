import React, { createContext, useContext, useState } from 'react';

// Create AuthContext
export const AuthContext = createContext();

// Create AuthProvider to manage auth state
export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        isLoggedIn: false,
        role: null,        // Add role property
        credits: 0         // Add credits property
    });

    return (
        <AuthContext.Provider value={[auth, setAuth]}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook for consuming AuthContext
export const useAuth = () => useContext(AuthContext);
