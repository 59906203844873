import React from 'react';
import axios from 'axios';
import { useNavigation } from '../../utilities/NavigationContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChatBubbleLeftIcon, HandThumbUpIcon, UserCircleIcon } from '@heroicons/react/20/solid';

const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString(); // Months are zero-based
    let year = date.getFullYear();
  
    // Add leading zero to day and month if they are less than 10
    day = day.length < 2 ? '0' + day : day;
    month = month.length < 2 ? '0' + month : month;
  
    return `${day}/${month}/${year}`;
  };

function truncateString(str, maxLength) {
    if (str.length > maxLength) {
        return str.substring(0, maxLength) + '...';
    } else {
        return str;
    }
}

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class Comment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            commentData : [],
            formData: {
                comment: "", 
                replyTo: "",
                subRepltyTo : "",
                like : 0
            },
            activeMenuCommentId: null,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);

    }

    componentDidMount() {
        this.fetchCommentData();
    }

    fetchCommentData = async () => {
        try {
          const response = await axios.get(`${EndPoint}/comments`, {
            withCredentials: true,
          });
          this.setState({ commentData: response.data });
        } catch (error) {
          console.error('Error fetching comments:', error);
          // Handle errors here
        }
    };

    // open settings menu
    handleMenuClick(e, commentId) {
        e.stopPropagation();

        this.setState(prevState => ({
            activeMenuCommentId: prevState.activeMenuCommentId === commentId ? null : commentId
        }));
    }

    // show the discussion on another page
    handleShow = (id) => {
        this.props.navigate(`/discussion?id=${id}`);
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    }

    handleSubmit() {
    
        const { formData } = this.state;

        axios.post(`${EndPoint}/comments`, formData, {withCredentials: true})
        .then(response => {
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            this.handleClear();
            this.fetchCommentData();
        })
        .catch(error => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        });
    }
    
    handleDelete(e, id) {
        e.stopPropagation();
        axios.delete(`${EndPoint}/comments/${id}`, {withCredentials: true})
        .then(response => {
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            this.handleClear();
            this.fetchCommentData();
        })
        .catch(error => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        });

    }

    handleClear() {
        this.setState({
            formData: {
                comment: "", 
                replyTo: "",
                subRepltyTo : "",
                like : 0
            }
        });
    }

    handleLike = async (e, id) => {
 
        e.stopPropagation();

        axios.post(`${EndPoint}/comments/like`, {id: id}, {withCredentials: true})
        .then(response => {
            toast.success(`You just like a comment. Thank you !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            this.handleClear();
            this.fetchCommentData();
        })
        .catch(error => {
            toast.error(`Error Like. Please try again`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        });
    }

    renderAllComments() {
        var comments = this.state.commentData;
    
        // Sort comments by date, then by likes, then by number of replies
        comments.sort((a, b) => {
            // Convert dates to timestamps for comparison
            const dateA = new Date(a.date).getTime();
            const dateB = new Date(b.date).getTime();

            // Sort by date (most recent first)
            if (dateA > dateB) return -1;
            if (dateA < dateB) return 1;

            // If dates are the same, sort by likes (most likes first)
            if (a.like > b.like) return -1;
            if (a.like < b.like) return 1;

            // If likes are the same, sort by number of replies (most replies first)
            const repliesA = comments.filter(comment => comment.replyTo === a._id).length;
            const repliesB = comments.filter(comment => comment.replyTo === b._id).length;
            
            return repliesB - repliesA;
        });

        return comments.map((item, index) => {
            var replies = comments.filter(rep => rep.replyTo === item._id);
            var numberReplies = replies.length;

            if (!item.replyTo) {
                if (item.comment) {
                    var truncatedString = truncateString(item.comment, 100);
                } else { truncatedString = ""}
                
                return (
                    <div key={index} className='border border-slate-200 bg-white' onClick={()=> this.handleShow(item._id)}>
                    <div className='rounded p-2 m-1 w-[90%] grid grid-cols-12 sm:grid-cols-10 md:grid-cols-10 xs:grid-cols-1 ' key={index}>
                        <div className='text-xs xs:col-span-12 sm:col-span-12'>
                            <UserCircleIcon className='w-14 h-14 text-slate-300 hover:text-slate-500' />
                        </div>
                        <div className='text-md col-span-10 break-words h-auto xs:col-span-12 sm:col-span-12'>
                            <p className='text-sm'>{item.userName}</p>
                            <p className='text-xs mb-1'>{item.userType}</p>
                            <p className='mb-1 sm:text-sm'>{truncatedString}</p>
                            <p className='text-xs my-1'>Posted on : {formatDate(item.date)}</p>
                        </div>
                        <div className='text-xs py-1'>
                        
                            <div className='flex flex-row gap-4 relative'>
                                <div className='flex flex-row gap-1'>      
                                    <div className='font-semibold text-sm p-1'>{item.like}</div>
                                    <button className={` ${item.like > 0 ? 'bg-yellow-300' : 'bg-slate-100'} hover:bg-slate-200 p-1 rounded  cursor-pointer text-sm`} onClick={(e) => {this.handleLike(e, item._id)}}>Like</button>
                              
                                </div>
                                <div className='flex flex-row gap-1'>
                                    <div className='font-semibold text-sm p-1'>{numberReplies}</div>
                                    <div className={` ${numberReplies > 0 ? 'bg-sky-300' : 'bg-slate-100'} hover:bg-slate-200 p-1 rounded text-sm`}>Discussion</div>
                                    
                                </div>
                                <div className='flex flex-row gap-1'>
                                <button className='text-sm px-3 py-1  hover:bg-slate-200' onClick={(e) => this.handleMenuClick(e, item._id)}>...</button>
                                {
                                    this.state.activeMenuCommentId === item._id &&
                                    <div className="submenu absolute w-24 rounded p-1 flex flex-col gap-1 mt-8 -ml-10 z-10 bg-slate-100">
                                        <button className="submenu-option text-left p-1 hover:bg-red-200">Report Post</button>
                                        <button className="submenu-option text-left p-1 hover:bg-red-200" onClick={(e) => this.handleDelete(e, item._id)}>Delete Post</button>
                                    </div>
                                }
                                </div>
                            </div>
                         
                        </div>
                  
                  
                    </div>
            
                    </div>
                )
            } 
        
        })
    }

    renderForm() {

        var formData = this.state.formData;
        return (
            <div>
            <textarea
            name="comment"
            value={formData.comment}
            onChange={this.handleChange}
            className="p-1 mt-2 mb-0 text-sm h-[80px] w-full resize-none rounded border-slate-200"
            />
             <button className='bg-sky-600 hover:bg-sky-700 text-white ml-2 p-2 w-32 rounded shadow-lg text-sm float-right' onClick={this.handleSubmit}>Submit</button>

           
            </div>
        )
    }

    render() {
        return (
            
            <div className="mt-20 mx-52 sm:mx-10 md:mx-20 xs:mx-10">
                <h1 className='text-center font-bold text-lg m-4'>Discussions</h1>
                <p className='text-center'>Feel free to submit any comments, feature request, or any other inputs.</p>
                <div className='mt-4 mb-14 px-52 sm:px-20 md:px-24 xs:px-2'>{this.renderForm()}</div>
                <div className='h-[700px] overflow-y-auto overflow-x-hidden p-4'>{this.renderAllComments()}</div>

                <ToastContainer />
            </div> 
        )
    }
} 


const CommentWithNavigation = (props) => {
    const navigate = useNavigation(); // Use the useNavigation hook here
    return <Comment {...props} navigate={navigate} />;
};

export default CommentWithNavigation;