import React, { Component } from 'react';

class ClipCopy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMacroActive: false,
      pastedContent: '',
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.activateMacroKeys = this.activateMacroKeys.bind(this);
  }

  componentDidMount() {
    // Add global keydown listener
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    // Cleanup listener when the component unmounts
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  activateMacroKeys() {
    this.setState({ isMacroActive: true });
    alert('Macro keys (Ctrl + M) are now activated!');
  }

  async handleKeyDown(event) {
    const { isMacroActive } = this.state;

    if (isMacroActive && event.ctrlKey && event.key === 'm') {
      event.preventDefault(); // Prevent default browser behavior

      try {
        // Use Clipboard API to read from clipboard
        if (navigator.clipboard && navigator.clipboard.readText) {
          const clipboardContent = await navigator.clipboard.readText();
          this.setState({ pastedContent: clipboardContent });
          console.log('Pasted content from clipboard:', clipboardContent);
        } else {
          alert('Clipboard API not supported in this browser.');
        }
      } catch (error) {
        console.error('Failed to read from clipboard:', error);
      }
    }
  }

  render() {
    const { pastedContent } = this.state;

    return (
      <div style={{ padding: '20px', fontFamily: 'Arial' }}>
        <h1>React Macro Keys Example</h1>
        <button
          onClick={this.activateMacroKeys}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            marginBottom: '20px',
          }}
        >
          Activate Macro Keys (Ctrl + M)
        </button>

        <textarea
          value={pastedContent}
          readOnly
          placeholder="Copied content will appear here..."
          style={{
            width: '100%',
            height: '200px',
            fontSize: '16px',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            resize: 'none',
          }}
        ></textarea>
      </div>
    );
  }
}

export default ClipCopy;
