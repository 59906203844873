import React, { Component } from 'react';
import axios from 'axios';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class Usage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usage: [],
      credit: 0,
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    // Fetch logs from the API when the component mounts
    axios
      .get(`${EndPoint}/user/credits`, { withCredentials: true })
      .then((response) => {
        this.setState({ usage: response.data, loading: false });
      })
      .catch((error) => {
        console.error('Error fetching logs:', error);
        this.setState({ error: error.message, loading: false });
      });
  }

  render() {
    const { logs, loading, error } = this.state;

    return (
      <div className="container mx-auto p-6">
        <h1 className="text-2xl font-bold mb-4">Usage Table</h1>
        {loading ? (<>loading</>):(
          <>
            <div className='font-semibold text-lg m-2'>Remaining Credits : {this.state.usage.credits}</div>
            <div className="overflow-x-auto">
            <table className="table-auto w-full border-collapse border border-gray-300 px-32">
              <thead>
                <tr className="bg-sky-600 text-white text-left">
                  <th className="border border-gray-300 px-4 py-2">Type</th>
                  <th className="border border-gray-300 px-4 py-2 text-center">Credit Used</th>
                  <th className="border border-gray-300 px-4 py-2 text-center">Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {this.state.usage.logs.map((log) => (
                  <tr key={log._id} className="hover:bg-gray-100">
                    <td className="border border-gray-300 px-4 py-2">{log.type}</td>
                    <td className="border border-gray-300 px-4 py-2 text-center">{log.credit}</td>
                    <td className="border border-gray-300 px-4 py-2 text-center">{new Date(log.timeStamp).toLocaleString()}</td>
                   
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          </>
        )}
        {/* {loading ? (
          <p className="text-gray-500">Loading...</p>
        ) : error ? (
          <p className="text-red-500">Error: {error}</p>
        ) : logs.length === 0 ? (
          <p className="text-gray-500">No logs available.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-200 text-left">
  
                  <th className="border border-gray-300 px-4 py-2">User ID</th>
                  <th className="border border-gray-300 px-4 py-2">Type</th>
                  <th className="border border-gray-300 px-4 py-2">Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log) => (
                  <tr key={log._id} className="hover:bg-gray-100">

                    <td className="border border-gray-300 px-4 py-2">{log.userId}</td>
                    <td className="border border-gray-300 px-4 py-2">{log.type}</td>
                    <td className="border border-gray-300 px-4 py-2">{new Date(log.timeStamp).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )} */}
      </div>
    );
  }
}

export default Usage;
