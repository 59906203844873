import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { textToLetter } from '../../utilities/WorkflowAI';

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

class Planning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            problems: [],
            newGoal: {}, // Track new goal input for each problem
            newPlan: {}, // Track new plan input for each problem and status
            editingGoalId: null, // Track which goal is being edited
            editingPlanId: null, // Track which plan entry is being edited
            editValue: '', // Track the current value being edited
            editingProblemId: null, // Track which problem is being edited
            editProblemValue: '',
            //active tab
            activeTabs : {},
            //editing
            editingJourneyId: null,  // Track the currently editing journey
            editingReviewId: null,   // Track the currently editing review
            editingJourneyText: '',
            editingJourneyDate: '',
            editingJourneyType: '',
            editingReviewText: '',
            editingReviewDate: '',
            //new
            newJourneyDate : new Date().toISOString().split("T")[0],
            newJourneyText : '',
            newReviewDate : new Date().toISOString().split("T")[0],
            newReviewText: '',
            //
            todayNote: '',
            displayNote: false,
            //
            letter: '',
            displayLetter: false
        };
        
    }


    // componentDidMount() {
    //     const activeTabs = {};
    //     if (this.props.problems) {
    //         this.props.problems.forEach((problem) => {
    //             activeTabs[problem.id] = 'planning'; // Set default tab to 'planning'
    //         });        
    //         this.setState({ problems: this.props.problems, activeTabs });
    //     } else {
    //         this.setState({ problems: [], activeTabs });
    //     }
    // }
    
    // componentDidUpdate(prevProps) {
    //     if (this.props.problems && prevProps.problems !== this.props.problems) {
    //         const activeTabs = { ...this.state.activeTabs };
    //         this.props.problems.forEach((problem) => {
    //             if (!activeTabs[problem.id]) {
    //                 activeTabs[problem.id] = 'planning'; // Set default tab to 'planning' if not already set
    //             }
    //         });
    //         this.setState({ problems: this.props.problems, activeTabs });
    //     }
    // }

    componentDidMount() {
        const activeTabs = {};
        if (this.props.problems) {
            const updatedProblems = this.props.problems.map((problem) => ({
                ...problem,
                selected: false, // Add the selected field
            }));
            updatedProblems.forEach((problem) => {
                activeTabs[problem.id] = 'planning'; // Set default tab to 'planning'
            });
            this.setState({ problems: updatedProblems, activeTabs });
        } else {
            this.setState({ problems: [], activeTabs });
        }
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.problems && prevProps.problems !== this.props.problems) {
            const activeTabs = { ...this.state.activeTabs };
            const updatedProblems = this.props.problems.map((problem) => ({
                ...problem,
                selected: problem.selected || false, // Preserve existing value or set to false
            }));
            updatedProblems.forEach((problem) => {
                if (!activeTabs[problem.id]) {
                    activeTabs[problem.id] = 'planning'; // Set default tab to 'planning' if not already set
                }
            });
            this.setState({ problems: updatedProblems, activeTabs });
        }
    }
    

    // Toggle collapse/expand
    toggleCollapse = (problemId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        collapsed: !problem.collapsed,
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            // Now `updatedProblems` is available within the callback as `this.state.problems`
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to delete a goal
    deleteGoal = (problemId, goalId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        goal: problem.goal.filter((goal) => goal.id !== goalId),
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            // Now `updatedProblems` is available within the callback as `this.state.problems`
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to delete a plan entry
    deletePlanEntry = (problemId, planId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        plan: problem.plan.filter((plan) => plan.id !== planId),
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to delete a journey
    deleteJourney = (problemId, journeyId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        journey: problem.journey.filter((journey) => journey.id !== journeyId),
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to delete a review
    deleteReview = (problemId, reviewId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        review: problem.review.filter((review) => review.id !== reviewId),
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to delete Problem
    deleteProblem = (problemId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.filter(problem => problem.id !== problemId);
            return { problems: updatedProblems };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    }
    

    // Method to add a new goal
    addGoal = (problemId) => {
        const { newGoal } = this.state;
        if (!newGoal[problemId] || newGoal[problemId].trim() === '') return; // Don't add empty goals

        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        goal: [
                            ...problem.goal,
                            { id: generateId(), entry: newGoal[problemId], dateAdded: new Date().toISOString().split("T")[0] },
                        ],
                    };
                }
                return problem;
            });

            return {
                problems: updatedProblems,
                newGoal: { ...prevState.newGoal, [problemId]: '' },
            };
        }, () => {
            // Now `updatedProblems` is available within the callback as `this.state.problems`
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to add a new plan entry
    addPlanEntry = (problemId, status) => {
        const { newPlan } = this.state;
        const key = `${problemId}-${status}`;
        const newEntry = newPlan[key];

        if (!newEntry || newEntry.trim() === '') return; // Don't add empty entries

        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        plan: [
                            ...problem.plan,
                            { id: generateId(), entry: newEntry, status: status, dateAdded: new Date().toISOString().split("T")[0] },
                        ],
                    };
                }
                return problem;
            });

            return {
                problems: updatedProblems,
                newPlan: { ...prevState.newPlan, [key]: '' },
            };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to add a new Journey
    addJourney = (problemId) => {
        const { newJourneyText, newJourneyDate, newJourneyType } = this.state;
        if (!newJourneyText || !newJourneyDate || !newJourneyType) return; // Ensure all fields are filled

        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        journey: [
                            ...problem.journey,
                            { id: generateId(), date: newJourneyDate, journey: newJourneyText, type: newJourneyType },
                        ],
                    };
                }
                return problem;
            });

            return {
                problems: updatedProblems,
                newJourneyText: '',
                newJourneyDate: new Date().toISOString().split("T")[0],
                newJourneyType: '',
            };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to add a new Review
    addReview = (problemId) => {
        const { newReviewText, newReviewDate } = this.state;
        if (!newReviewText || !newReviewDate) return; // Ensure all fields are filled

        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        review: [
                            ...problem.review,
                            { id: generateId(), date: newReviewDate, notes: newReviewText },
                        ],
                    };
                }
                return problem;
            });

            return {
                problems: updatedProblems,
                newReviewText: '',
                newReviewDate: new Date().toISOString().split("T")[0],
            };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    addNewProblem = () => {
        const newProblem = {
            id: generateId(),
            problem: 'Double click to edit', // You can change this to a default entry or leave it as an empty string
            category: 'Chronic Disease',
            priority: 'Low',
            goal: [],
            plan: [],
            journey: [],
            review: [],
            collapsed: false
        };
    
        this.setState((prevState) => ({
            problems: [newProblem, ...prevState.problems]
        }), () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    }
    

    startEditingJourney = (item) => {
        this.setState({
            editingJourneyId: item.id,
            editingJourneyText: item.journey,
            editingJourneyDate: item.date,
            editingJourneyType: item.type,
        });
    };
    
    startEditingReview = (item) => {
        this.setState({
            editingReviewId: item.id,
            editingReviewText: item.notes,
            editingReviewDate: item.date,
        });
    };

    startEditingProblem = (problemId, currentProblem) => {
        this.setState({
            editingProblemId: problemId,
            editProblemValue: currentProblem
        });
    }
    

    updateJourney = (problemId, journeyId) => {
        const { editingJourneyText, editingJourneyDate, editingJourneyType } = this.state;
        
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        journey: problem.journey.map((journey) => {
                            if (journey.id === journeyId) {
                                return {
                                    ...journey,
                                    journey: editingJourneyText,
                                    date: editingJourneyDate,
                                    type: editingJourneyType,
                                };
                            }
                            return journey;
                        }),
                    };
                }
                return problem;
            });
    
            return {
                problems: updatedProblems,
                editingJourneyId: null,
                editingJourneyText: '',
                editingJourneyDate: '',
                editingJourneyType: '',
            };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };
    
    updateReview = (problemId, reviewId) => {
        const { editingReviewText, editingReviewDate } = this.state;
    
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        review: problem.review.map((review) => {
                            if (review.id === reviewId) {
                                return {
                                    ...review,
                                    notes: editingReviewText,
                                    date: editingReviewDate,
                                };
                            }
                            return review;
                        }),
                    };
                }
                return problem;
            });
    
            return {
                problems: updatedProblems,
                editingReviewId: null,
                editingReviewText: '',
                editingReviewDate: '',
            };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };
    
    // Handle key press for adding new entries
    handleKeyPress = (event, problemId, type, status) => {
        if (event.key === 'Enter') {
            if (type === 'goal') {
                this.addGoal(problemId);
            } else if (type === 'plan') {
                this.addPlanEntry(problemId, status);
            }
        }
    };

    // Handle input changes
    handleInputChange = (event, problemId, type, status) => {
        const value = event.target.value;

        if (type === 'goal') {
            this.setState((prevState) => ({
                newGoal: { ...prevState.newGoal, [problemId]: value },
            }));
        } else if (type === 'plan') {
            const key = `${problemId}-${status}`;
            this.setState((prevState) => ({
                newPlan: { ...prevState.newPlan, [key]: value },
            }));
        }
    };

    // Handle double-click to edit
    handleDoubleClick = (entryId, type, currentValue) => {
    
        if (type === 'goal') {
            this.setState({ editingGoalId: entryId, editValue: currentValue });
        } else if (type === 'plan') {
            this.setState({ editingPlanId: entryId, editValue: currentValue });
        } else if (type === 'review') {
            this.setState({editingReviewId: entryId, editingReviewText: currentValue.notes, editingReviewDate: currentValue.date})
        } else if (type === 'journey') {
            this.setState({editingJourneyId: entryId, editingJourneyText: currentValue.journey, editingJourneyDate: currentValue.date, editingJourneyType: currentValue.type})
        }
    };

    // Handle change while editing
    handleEditChange = (event) => {
        this.setState({ editValue: event.target.value });
    };

    // Save edited value
    handleEditSave = (event, problemId, type) => {
        if (event.key === 'Enter') {
            this.setState((prevState) => {
                const updatedProblems = prevState.problems.map((problem) => {
                    if (problem.id === problemId) {
                        if (type === 'goal') {
                            return {
                                ...problem,
                                goal: problem.goal.map((goal) => {
                                    if (goal.id === prevState.editingGoalId) {
                                        return { ...goal, entry: prevState.editValue };
                                    }
                                    return goal;
                                }),
                            };
                        } else if (type === 'plan') {
                            return {
                                ...problem,
                                plan: problem.plan.map((plan) => {
                                    if (plan.id === prevState.editingPlanId) {
                                        return { ...plan, entry: prevState.editValue };
                                    }
                                    return plan;
                                }),
                            };
                        }
                    }
                    return problem;
                });

                return {
                    problems: updatedProblems,
                    editingGoalId: null,
                    editingPlanId: null,
                    editValue: '',
                };
            });
        }
    };

    handleProblemEditChange = (event) => {
        this.setState({ editProblemValue: event.target.value });
    }
    
    handleProblemEditSave = (event, problemId) => {
        if (event.key === 'Enter') {
            this.setState((prevState) => {
                const updatedProblems = prevState.problems.map((problem) => {
                    if (problem.id === problemId) {
                        return {
                            ...problem,
                            problem: prevState.editProblemValue
                        };
                    }
                    return problem;
                });
    
                return {
                    problems: updatedProblems,
                    editingProblemId: null,
                    editProblemValue: ''
                };
            }, () => {
                this.props.onProblemsUpdate(this.state.problems);
            });
        }
    }
    
    // Handle drag end
    onDragEnd = (result, problemId) => {
        const { destination, draggableId } = result;
        if (!destination) return;
    
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    const draggedItem = problem.plan.find((plan) => plan.id === draggableId);
    
                    // Remove dragged item from its original place
                    const newPlan = problem.plan.filter((plan) => plan.id !== draggableId);
    
                    // Add dragged item to the new status
                    draggedItem.status = destination.droppableId;
                    newPlan.splice(destination.index, 0, draggedItem);
    
                    return {
                        ...problem,
                        plan: newPlan,
                    };
                }
                return problem;
            });
    
            return { problems: updatedProblems };
        }, () => {
            
            // Now `updatedProblems` is available within the callback
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // send transcription to parent component
    handleInputUpdate = async (newTranscription, toastId) => {

        this.props.onVoiceInput(newTranscription, toastId)
    };

    // Processing paste data
    handlePaste = async (pasteData, toastId) => {

        this.props.onPaste(pasteData, toastId)

    }

    // Method to toggle priority
    togglePriority = (problemId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    let newPriority;
                    if (problem.priority === 'Inactive') {
                        newPriority = "Low"
                    } else if (problem.priority === "Low") {
                        newPriority = "Moderate";
                    } else if (problem.priority === "Moderate") {
                        newPriority = "High";
                    } else {
                        newPriority = "Inactive";
                    }
                    return {
                        ...problem,
                        priority: newPriority,
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to toggle category
    toggleCategory = (problemId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    let newCategory;
                    if (problem.category === 'Chronic Disease') {
                        newCategory = "Lifestyle"
                    } else if (problem.category === "Lifestyle") {
                        newCategory = "Mental Health";
                    } else if (problem.category === "Mental Health") {
                        newCategory = "Prevention";
                    } else if (problem.category === "Prevention") {
                        newCategory = "Social";
                    } else if (problem.category === "Social") {
                        newCategory = "Symptoms";
                    } else {
                        newCategory = "Chronic Disease";
                    }
                    return {
                        ...problem,
                        category: newCategory,
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Sorting problems by priority 
    sortingPriority = () => {

        const {problems} = this.state;
        
        // Only sort if there are problems
        var sortedProblems;
        if (problems && problems.length > 0) {
            sortedProblems = problems.sort((a, b) => {
                const priorityOrder = { High: 1, Moderate: 2, Low: 3, Inactive: 4 };
                return priorityOrder[a.priority] - priorityOrder[b.priority];
            });
        } else {
            sortedProblems = problems;
        }

        this.setState({problems: sortedProblems});

    }

    // Creating Today's Note
    createNote = () => {
        const problems = this.state.problems;
        const today = new Date().toISOString().split('T')[0]; // format today's date in 'YYYY-MM-DD'
        let reviews = [];
        let plans = [];
        let reviewIndex = 1; // Global counter for reviews
    
        // List of problem review today
        problems.forEach(problem => {
            problem.review.forEach(review => {
                if (review.date === today) {
                    reviews.push(`#${reviewIndex} ${problem.problem}:\n      ${review.notes}\n`);
                    reviewIndex++; // Increment the global counter for each review
                }
            });
        });
    
        // List of problem plan today
        problems.forEach(problem => {
            problem.plan.forEach(plan => {
                if (plan.dateAdded === today) {
                    plans.push(`- ${plan.entry}\n`);
                }
            });
        });
    
        // Combine reviews and plans into the final note format
        const note = `${reviews.join('')}\n\nPlan:\n${plans.join('')}`;
        this.setState({todayNote: note, displayNote:true});
    };

    renderNote() {
        return (
            this.state.displayNote && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-start"
                    onClick={() => this.setState({ displayNote: false })}
                >
                    <div
                        className="bg-white shadow-lg p-6 mt-20 w-[80%] rounded transform transition-transform duration-300 ease-in-out"
                        onClick={(e) => e.stopPropagation()} // Prevents modal close on inner div click
                    >
                        <div>
                            <h1 className='font-semibold m-2 text-center text-xl'>Today's Note</h1>
                            <textarea
                                id="noteText"
                                value={this.state.todayNote}
                                className="w-full h-[500px] border-gray-200 resize-none"
                            />
                            <button
                                className="text-white text-md my-2 p-4 w-full rounded shadow-lg bg-sky-800"
                                type="button"
                                onClick={() => navigator.clipboard.writeText(this.state.todayNote)}
                            >
                                Copy
                            </button>
                        </div>
                    </div>
                </div>
            )
        );
    }
    
    // Creating Referral Letter
    createLetter = async () => {
        const problems = this.state.problems;
        const today = new Date().toISOString().split('T')[0]; // format today's date in 'YYYY-MM-DD'
        const selectedProblem = problems.filter(item => item.selected === true)
        const respond = await textToLetter(JSON.stringify(selectedProblem));
        console.log(respond)
        this.setState({letter: respond, displayLetter:true});
    };
    handleCheckboxChange = (id) => {
        const updatedProblems = this.state.problems.map((problem) =>
            problem.id === id ? { ...problem, selected: !problem.selected } : problem
        );
        this.setState({ problems: updatedProblems });
    };

    renderLetter() {
        return (
            this.state.displayLetter && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-start"
                    onClick={() => this.setState({ displayLetter: false })}
                >
                    <div
                        className="bg-white shadow-lg p-6 mt-20 w-[80%] rounded transform transition-transform duration-300 ease-in-out"
                        onClick={(e) => e.stopPropagation()} // Prevents modal close on inner div click
                    >
                        <div>
                            <h1 className='font-semibold m-2 text-center text-xl'>Referral Letter</h1>
                            <textarea
                                id="noteText"
                                value={this.state.letter}
                                className="w-full h-[500px] border-gray-200 resize-none"
                            />
                            <button
                                className="text-white text-md my-2 p-4 w-full rounded shadow-lg bg-sky-800"
                                type="button"
                                onClick={() => navigator.clipboard.writeText(this.state.letter)}
                            >
                                Copy
                            </button>
                        </div>
                    </div>
                </div>
            )
        );
    }
    

    
    render() {
        var { problems, newGoal, newPlan, mewJourneyDate, newReviewDate, editingGoalId, editingPlanId, editValue,  editingReviewId, editingJourneyId } = this.state;

        // Show Category of Plan
        var category = this.props.category;
        if (category === "Chronic Disease") {
            problems = problems.filter(problem => 
                problem.category === "Chronic Disease" || 
                problem.category === "Lifestyle" ||
                problem.category === "Prevention" ||
                problem.category === "Symptoms"
            )
        } else if (category === "Mental Health") {
            problems = problems.filter(problem => 
                problem.category === "Mental Health" || 
                problem.category === "Lifestyle" || 
                problem.category === "Social"
            )
        } 

        return (
            <div className='col-span-6 bg-slate-50 m-2'>

                <div className='flex justify-center'>  
                <button className="text-md bg-sky-600 hover:bg-sky-700 text-white rounded text-center p-2 m-2 cursor-pointer w-60" onClick={this.addNewProblem}>Add Problem</button>
                <button className='text-md bg-slate-200 p-2 m-2 rounded shadow' onClick={this.sortingPriority}>Refresh</button>
                <button className='text-md bg-slate-200 p-2 m-2 rounded shadow' onClick={this.createNote}>Create Note</button>
                <button className='text-md bg-slate-200 p-2 m-2 rounded shadow' onClick={this.createLetter}>Create Letter</button>
                {this.renderNote()}
                {this.renderLetter()}
                </div>
     
                {/* Render Problem */}
                {problems.map((item) => (
                    <div key={item.id}
                        className={`m-2 p-2 rounded border-l-8 bg-slate-200 ${
                        item.priority === "High" ? "border-red-700" :
                        item.priority === "Moderate" ? "border-yellow-400" : 
                        item.priority === "Low" ? "border-green-400" :
                        "border-slate-500"
                        
                    }`}
                    >
                     
                    <div className='col-span-4 text-md font-semibold m-2'>
                        {this.state.editingProblemId === item.id ? (
                            <>
                            <input
                                value={this.state.editProblemValue}
                                onChange={this.handleProblemEditChange}
                                onKeyPress={(e) => this.handleProblemEditSave(e, item.id)}
                                className='border-slate-100 focus:border-slate-200 rounded placeholder-gray-400 w-80'
                            />
                            </>
                        ) : (
                            <span 
                            className='cursor-pointer'
                            onDoubleClick={() => this.startEditingProblem(item.id, item.problem)}>
                                {item.problem}
                            </span>
                        )}
                        <span className='float-right'>
                         <input
                                type="checkbox"
                                checked={item.selected}
                                onChange={() => this.handleCheckboxChange(item.id)}
                                className='border-slate-200 h-6 w-6'
                            />
                        </span>
                        <span className="text-sm mx-2 italic rounded text-black p-1 font-light float-right cursor-pointer" onClick={() => this.toggleCollapse(item.id)}>
                            {item.collapsed ? "Click to expand" : "Click to collapse"}
                        </span>
                        <span className='float-right bg-red-700 rounded p-1 mx-2 text-sm font-light hover:bg-red-800 cursor-pointer w-20 text-center text-white' onClick={()=> this.deleteProblem(item.id)}>Delete</span>
                        <span
                            className={`text-sm mx-2 rounded p-1 font-light float-right cursor-pointer w-20 text-center ${
                                    item.priority === "High" ? "bg-red-700 text-white" :
                                    item.priority === "Moderate" ? "bg-yellow-400 text-black" : 
                                    item.priority === "Low" ? "bg-green-400 text-black" :
                                    "bg-slate-500 text-gray-300"}`}
                            onClick={() => this.togglePriority(item.id)}
                        > 
                            {item.priority}
                        </span>
                        <span className={`text-sm mx-2 rounded p-1 font-light float-right cursor-pointer w-32 text-center ${
                                    item.category === "Mental Health" ? "bg-red-200 text-black" :
                                    item.category === "Social" ? "bg-yellow-200 text-black" : 
                                    item.category === "Lifestyle" ? "bg-green-200 text-black" :
                                    item.category === "Prevention" ? "bg-purple-200 text-black" :
                                    item.category === "Symptoms" ? "bg-red-200 text-black" :
                                    "bg-sky-200 text-black"}`}
                                    onClick={() => this.toggleCategory(item.id)}
                        >
                            {item.category}
                        </span>

                    </div>

                        {!item.collapsed && (
                            <DragDropContext onDragEnd={(result) => this.onDragEnd(result, item.id)}>
                            <div className='grid grid-cols-5' >
             
                                <div className='col-span-5 grid grid-cols-5 grid-rows-3'>
                 

                                    {/* Goal Section*/}
    
                                        <div className='m-2 text-sm bg-white rounded'>
                            
                                            <div className='m-1'>
                                                <p className='p-2 text-md font-semibold text-center rounded mx-1 my-2 bg-sky-200'>Goals</p>
                                                {item.goal.map((each) => (
                                                    <div key={each.id} className='bg-slate-100 p-2 m-1 flex justify-between items-center'>
                                                        {editingGoalId === each.id ? (
                                                            <input
                                                                value={editValue}
                                                                onChange={this.handleEditChange}
                                                                onKeyPress={(e) => this.handleEditSave(e, item.id, 'goal')}
                                                                className='border-slate-100 focus:border-slate-200 rounded w-full placeholder-gray-400'
                                                            />
                                                        ) : (
                                                            <span
                                                                className='cursor-pointer'
                                                                onDoubleClick={() => this.handleDoubleClick(each.id, 'goal', each.entry)}
                                                            >
                                                                {each.entry}
                                                            </span>
                                                        )}
                                                        <button
                                                            className='text-slate-800 ml-4'
                                                            onClick={() => this.deleteGoal(item.id, each.id)}
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                ))}
                                                <div className='p-2 text-md flex justify-between items-center'>
                                                    <input
                                                        placeholder='Type in new goal here'
                                                        className='border-slate-100 focus:border-slate-200 rounded w-full placeholder-gray-400'
                                                        value={newGoal[item.id] || ''}
                                                        onChange={(e) => this.handleInputChange(e, item.id, 'goal')}
                                                        onKeyPress={(e) => this.handleKeyPress(e, item.id, 'goal')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                           

                                    {/* Review Section*/}
              
                                        <div className='m-2 text-sm bg-white rounded row-span-3'>
                            
                                            <div className='m-1'>
                                                <p className='p-2 text-md font-semibold text-center rounded mx-1 my-2 bg-sky-200'>Reviews</p>
                                                {item.review.map((each) => (
                                                    <div key={each.id} className='bg-slate-100 p-2 m-1 flex justify-between items-center'>
                                                        {editingReviewId === each.id ? (
                                                        <div>
                                                            <textarea 
                                                                className='bg-white border border-slate-200 rounded w-full h-24 resize-none placeholder:italic' 
                                                                value={this.state.editingReviewText}
                                                                onChange={(e) => this.setState({ editingReviewText: e.target.value })}
                                                            />
                                                            <input 
                                                                type='date' 
                                                                className='bg-white border border-slate-200 rounded w-full text-gray-400 my-2'
                                                                value={this.state.editingReviewDate}
                                                                onChange={(e) => this.setState({ editingReviewDate: e.target.value })}
                                                            />
                                                            <button 
                                                                className='bg-sky-500 text-white rounded p-2 mt-2 w-full'
                                                                onClick={() => this.updateReview(item.id, each.id)}
                                                            >
                                                                Update Review
                                                            </button>
                                                        </div>
                                                        ) : (
                                                            <>
                                                            <span
                                                                className='cursor-pointer'
                                                                onDoubleClick={() => this.handleDoubleClick(each.id, 'review', each)}
                                                            >
                                                                <p className='text-xs italic m-1'>{new Date(each.date).toLocaleDateString()}</p>

                                                                {each.notes}
                                                            </span>
                                                            <button
                                                                    className='text-slate-800 ml-4'
                                                                    onClick={() => this.deleteReview(item.id, each.id)}
                                                                >
                                                                    X
                                                            </button>
                                                            </>
                                                        )}
                                                
                                                    </div>
                                                ))}
                                                <div className='p-2'>
                                                    <textarea 
                                                        className='bg-white border border-slate-200 rounded w-full h-36 resize-none placeholder:italic' 
                                                        placeholder='Type in review note here'
                                                        value={this.state.newReviewText || ''}
                                                        onChange={(e) => this.setState({ newReviewText: e.target.value })}
                                                    />
                                                    <input 
                                                        type='date' 
                                                        className='bg-white border border-slate-200 rounded w-full text-gray-400 my-2'
                                                        value={this.state.newReviewDate || ''}
                                                        onChange={(e) => this.setState({ newReviewDate: e.target.value })}
                                                    />
                                                    {this.state.newReviewText === "" ? (
                                                        <></>
                                                    ): (
                                                    <button 
                                                        className='bg-sky-500 text-white rounded p-2 mt-2 w-full'
                                                        onClick={() => this.addReview(item.id)}
                                                    >
                                                        Add Review
                                                    </button>
                                                    )}
                                              
                                                </div>
                                            </div>
                                        </div>
                             

                                    {/* Action Plan Section*/}
                                   
                                        {['plan', 'doing', 'done'].map((status) => (
                                            <Droppable droppableId={status} key={status}>
                                                {(provided) => (
                                                    <div
                                                        className='bg-white rounded m-2 row-span-3'
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                    >
                                                        <div
                                                            className={` p-2 text-md text-center rounded m-2 ${
                                                                status === 'plan'
                                                                    ? 'bg-yellow-400 text-black'
                                                                    : status === 'doing'
                                                                    ? 'bg-red-700 text-white'
                                                                    : status === 'pending'
                                                                    ? 'bg-slate-500 text-white'
                                                                    : 'bg-teal-600 text-white'
                                                            }`}
                                                        >
                                                            {status.charAt(0).toUpperCase() + status.slice(1)}
                                                        </div>
                                                        {item.plan
                                                            .filter((each) => each.status === status)
                                                            .map((each, idx) => (
                                                                <Draggable key={each.id} draggableId={each.id} index={idx}>
                                                                    {(provided) => (
                                                                        <div
                                                                            className='bg-slate-200 m-2 p-4 text-md flex justify-between items-center rounded'
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            {editingPlanId === each.id ? (
                                                                                <input
                                                                                    value={editValue}
                                                                                    onChange={this.handleEditChange}
                                                                                    onKeyPress={(e) =>
                                                                                        this.handleEditSave(e, item.id, 'plan')
                                                                                    }
                                                                                    className='border-slate-100 focus:border-slate-200 rounded w-full placeholder-gray-400'
                                                                                />
                                                                            ) : (
                                                                                <span
                                                                                    className='cursor-pointer'
                                                                                    onDoubleClick={() =>
                                                                                        this.handleDoubleClick(each.id, 'plan', each.entry)
                                                                                    
                                                                                    }
                                                                                >
                                                                                      <p className="text-gray-700 text-xs p-1 place-self-start">{new Date(each.dateAdded).toLocaleDateString()}</p>  
                                                                                    {each.entry}
                                                                                </span>
                                                                            )}
                                                                            <button
                                                                                className='text-slate-800 ml-4'
                                                                                onClick={() => this.deletePlanEntry(item.id, each.id)}
                                                                            >
                                                                                X
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                        {provided.placeholder}
                                                        <div className='p-2 text-md flex justify-between items-center'>
                                                            <input
                                                                placeholder={`Type in new task here`}
                                                                className='border-slate-100 focus:border-slate-200 rounded w-full placeholder-gray-400'
                                                                value={newPlan[`${item.id}-${status}`] || ''}
                                                                onChange={(e) =>
                                                                    this.handleInputChange(e, item.id, 'plan', status)
                                                                }
                                                                onKeyPress={(e) =>
                                                                    this.handleKeyPress(e, item.id, 'plan', status)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </Droppable>
                                        ))
                                    }

                                    {/* Journey Section*/}
                                        <div className='m-2 text-sm bg-white rounded row-span-2'>
                            
                                            <div className='m-1'>
                                                <p className='p-2 text-md font-semibold text-center rounded mx-1 my-2 bg-sky-200'>Progress</p>
                                                {item.journey.map((each) => (
                                                    <div key={each.id} className='bg-slate-100 p-2 m-1 flex justify-between items-center'>
                                                        {editingJourneyId === each.id ? (
                                                       <div className="bg-slate-300 p-2">
                                                       <textarea 
                                                           className="bg-white border border-slate-200 rounded w-full h-24 resize-none placeholder:italic" 
                                                           value={this.state.editingJourneyText}
                                                           onChange={(e) => this.setState({ editingJourneyText: e.target.value })}
                                                       />
                                                       <input 
                                                           type="date" 
                                                           className="bg-white border border-slate-200 rounded w-full text-gray-400 my-2"
                                                           value={this.state.editingJourneyDate}
                                                           onChange={(e) => this.setState({ editingJourneyDate: e.target.value })}
                                                       />
                                                       <select 
                                                           className="bg-white border border-slate-200 rounded w-full text-black my-1"
                                                           value={this.state.editingJourneyType}
                                                           onChange={(e) => this.setState({ editingJourneyType: e.target.value })}
                                                       >
                                                           <option value="">-Select type-</option>
                                                           <option value="Diagnosis">Diagnosis</option>
                                                           <option value="Pathology">Pathology</option>
                                                           <option value="Imaging">Imaging</option>
                                                           <option value="Report">Report</option>
                                                           <option value="Event">Event</option>
                                                       </select>
                                                       <button 
                                                           className="bg-sky-500 text-white rounded p-2 mt-2 w-full"
                                                           onClick={() => this.updateJourney(item.id, this.state.editingJourneyId)}
                                                       >
                                                           Update Progress
                                                       </button>
                                                       </div>
                                                        ) : (
                                                            <>
                                                            <span
                                                                className='cursor-pointer'
                                                                onDoubleClick={() => this.handleDoubleClick(each.id, 'journey', each)}
                                         
                                                            >
                                                                <div className="grid grid-cols-2">
                                                                <p className="text-gray-700 text-xs p-1 place-self-start">{new Date(each.date).toLocaleDateString()}</p>  
                                                                <h3 className="text-xs bg-slate-200 p-1 text-gray-700 place-self-end">{each.type}</h3>
                                                                </div>
                                                             
                                                                {each.journey}
                                                                
                                                            </span>
                                                            <button
                                                                className='text-slate-800 ml-4'
                                                                onClick={() => this.deleteJourney(item.id, each.id)}
                                                            >
                                                            X
                                                            </button>
                                                            </>
                                                        )}
                                               
                                                    </div>
                                                ))}
                                                <div className='p-2'>
                                                    <textarea 
                                                        className='bg-white border border-slate-200 rounded w-full h-24 resize-none placeholder:italic' 
                                                        placeholder='Type in journey here'
                                                        value={this.state.newJourneyText || ''}
                                                        onChange={(e) => this.setState({ newJourneyText: e.target.value })}
                                                    />
                                                    <input 
                                                        type='date' 
                                                        className='bg-white border border-slate-200 rounded w-full text-gray-400 my-2'
                                                        value={this.state.newJourneyDate || ''}
                                                        onChange={(e) => this.setState({ newJourneyDate: e.target.value })}
                                                    />
                                                    <select 
                                                        className='bg-white border border-slate-200 rounded w-full text-black my-1'
                                                        value={this.state.newJourneyType || ''}
                                                        onChange={(e) => this.setState({ newJourneyType: e.target.value })}
                                                    >
                                                        <option value="">-Select type-</option>
                                                        <option value="Diagnosis">Diagnosis</option>
                                                        <option value="Pathology">Pathology</option>
                                                        <option value="Imaging">Imaging</option>
                                                        <option value="Report">Report</option>
                                                        <option value="Event">Event</option>
                                                    </select>
                                                    {this.state.newJourneyText === "" ? (
                                                        <></>
                                                    ):(
                                                    <button 
                                                        className='bg-sky-500 text-white rounded p-2 mt-2 w-full'
                                                        onClick={() => this.addJourney(item.id)}
                                                    >
                                                        Add Progress
                                                    </button>
                                                    )}
                                            
                                                </div>
                                            </div>
                                        </div>
                                  
                                </div>
                            </div>
                        </DragDropContext>
                        
                        )}
                    </div>
                ))}
            </div>
        );
    }
    
}

export default Planning;