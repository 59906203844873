import { AITextToObject, AITextToText, AITextToObjectwithData, AITextToObjectwithDataAndRef } from './AI';
import axios from 'axios';
const EndPoint = process.env.REACT_APP_API_ENDPOINT;

// Helpers
function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    const createProblemWithIds = (problem) => ({
        ...problem,
        id: generateId(),
        priority: "Low",
        collapsed: true,
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
        journey: Array.isArray(problem.journey) ? problem.journey.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
        review: Array.isArray(problem.review) ? problem.review.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
    });

    // Check if `problems` is not an array, and convert to array of one object
    if (!Array.isArray(problems)) {
        return [createProblemWithIds(problems)];
    }

    // Map over array of problems and regenerate ids
    return problems.map(createProblemWithIds);
};

const regenerateRefIds = (problems) => {
    const createProblemWithIds = (problem) => ({
        ...problem,
        id: generateId(),
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
        education: Array.isArray(problem.education) ? problem.education.map(e => ({
            ...e,
            id: generateId(),
        })) : [] // Fallback to an empty array if education is not present or not an array
    });

    // Ensure `problems` is an array; wrap in an array if it's a single object
    const problemsArray = Array.isArray(problems) ? problems : [problems];

    // Map over array of problems and regenerate ids
    return problemsArray.map(createProblemWithIds);
};

const regenerateContactIds = (contacts) => {
    const createContactWithId = (contact) => ({
        ...contact,
        id: generateId(),
    });

    // Ensure `contacts` is an array; wrap in an array if it's a single object
    const contactsArray = Array.isArray(contacts) ? contacts : [contacts];

    // Map over array of contacts and regenerate ids
    return contactsArray.map(createContactWithId);
};

function addFieldsToProblems(problems) {
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];

    // Ensure problems is an array
    if (problems.length > 0) {
        return problems.map(problem => ({
        ...problem,
        dateAdded: formattedDate,
        id: generateId(),
        priority: "Low",
        collapsed: true,
        review:  (problem.review || []).map(g => ({
            ...g,
            id: generateId()
        })),
        journey: (problem.journey || []).map(g => ({
            ...g,
            id: generateId(),
            dateAdded: formattedDate
        })),
        goal: (problem.goal || []).map(g => ({
            ...g,
            id: generateId(),
            dateAdded: formattedDate
        })),
        plan: (problem.plan || []).map(p => ({
            ...p,
            id: generateId(),
            dateAdded: formattedDate
        }))
        }));
    }
  
}

// AI Instruction
const instructionWritingNote = `
You are a medical professional writing medical notes from the data.
Do not add anything outside of the given input. Use British spelling.
Don"t add any other comment.
Avoid repetition within the notes.
Sort it with the following order, and ensure that each section title is followed by a colon and a space, write the content underneath:
- Subjective: List multiple problems under numbered sections with clear subheadings. For each problem, include detailed information underneath using bullet points. If there is only one problem, do not use numbering.
  Format:
    #1 Problem name:
      - Detailed information point 1
      - Detailed information point 2
- Objective: Any physical examination if applicable. If there is no content, do not include the "Objective:" section.
- Assessment: List each diagnosis or problem as a bullet point.
  Format:
    - Problem 1
    - Problem 2
- Plan: For each problem, create a numbered section with subheadings. Include detailed action steps underneath each numbered section using bullet points.
  Format:
    #1 Problem name:
      - Plan item 1
      - Plan item 2

Also create a patient instruction at home with the following details : 
- Medication to be taken, or any change of medication dosage. 
- Any safety netting such as if develop worsening symptoms, go to hospital. If develop agitation, restless from taking the medication, stop it, then report. 
- List of the consultation points, assessment and management plan, briefly.
- Any follow up schedule. 

Format the output as a flat JSON object in the following structure:
{
  "consultation": "multiline medical notes with each section starting on a new line, separated by a blank line",
  "tag": [
    "Select from this predefined list only, based on relevance to the consultation:",
    "Mental Health" (if related to mental health issues such as depression, anxiety, PTSD, low self esteem, distress coping etc.),
    "Chronic Disease" (if related to chronic diseases such as Diabetes, Coeliac disease, Hypertension, etc.),
    "Symptoms" (if related to symptoms such as chest pain, knee pain, abdominal pain, etc.),
    "Lifestyle" (if related to smoking, alcohol, caffeine intake, physical activities, diet, or nutrition),
    "Prevention" (if related to vaccination, cancer screening, surveillance such as repeat colonoscopy, repeat CT scan of lungs, etc.),
    "Social" (if related to social issues such as family relationships, social support, occupation, legal matters, finance, driving, or daily activities)
    ],
  "instruction" : "As above instruction"
}

Additional Guidelines:
- For "Subjective", use numbered sections only if multiple problems are mentioned.
- For "Assessment", write each problem or diagnosis as a bullet point.
- For "Plan", use numbered sections corresponding to the problems in "Subjective" or "Assessment". Include detailed actions under each numbered section using bullet points.
- Write each section of the medical notes on a new line, with a blank line between sections.
- Use only the tags listed above. Do not create new tags.

Example Input:
- Patient complains of abdominal pain, has a history of coeliac disease, and has been diagnosed with depression for two years. The patient is not strictly adhering to a gluten-free diet. Abdominal pain is ongoing, and depression is poorly controlled with medication (Flux 20 mg once daily).

Example Output:
{
  "consultation": "Subjective:\\n#1 Coeliac disease:\\n   - Patient has been diagnosed with coeliac disease\\n   - Currently not on strict gluten-free diet\\n\\n#2 Abdominal pain:\\n   - Patient has been complaining of abdominal pain\\n\\n#3 Depression:\\n   - Diagnosed with depression two years ago\\n   - Currently on Flux, a thin 20-milligram tablet once daily\\n   - Depression still not in good control\\n\\nAssessment:\\n- Coeliac disease\\n- Abdominal pain\\n- Depression\\n\\nPlan:\\n#1 Coeliac disease:\\n   - Referral to dietitian\\n\\n#2 Abdominal pain:\\n   - Investigations for underlying cause\\n\\n#3 Depression:\\n   - Referral to psychologist\\n   - Referral to psychiatrist",
  "tag": ["Chronic Disease", "Symptoms", "Mental Health"], 
  "instruction" : "#1 Chest pain:\n  -Discuss about chest pain\n  -Noted previous history of heart disease\n  -Plan for ECG and echocardiography \n\n#2 Repeat prescription: \n  - Prescription sent to pharmacy via escript"
}
`;


const instructionTextToSummary= `
   Your task is to organise the provided information from userInput into an ordered format. Use British spelling throughout.

You will receive 2 types of data:
1. 'input': A text containing medical notes.
2. 'initData': A JSON object containing:
   - patientData
   - allergies
   - medications
   - activeHistory
   - pastHistory
   - immunisations
   - familyHistory
   - emergency
   - smoking
   - alcohol
   - substance

### Task:
1. Read through 'input'.
2. Extract relevant information from 'input', including:
   - First name, last name, date of birth, address, suburb, postcode, mobile phone.
   - List of allergies.
   - List of medications.
   - List of active past history.
   - List of inactive past history.
   - List of immunisations.
   - List of family history.
   - List of emergency contacts.
   - Smoking history.
   - Alcohol history.
   - Substance use history.

3. Update 'initData' by merging the extracted information into it:
   - For lists such as allergies, medications, activeHistory, pastHistory, immunisations, familyHistory, and emergency, append new entries from 'input' that do not already exist in 'initData' (based on unique identifiers or sufficient matching fields).
   - Update existing entries in 'initData' with new details from 'input' where applicable.
   - Remove entries only if explicitly stated in 'input' (e.g., "remove allergy to penicillin").
   - For smoking, alcohol, and substance, update fields or append additional notes if applicable, while preserving existing data unless explicitly overridden.

4. The final output must retain all original 'initData' fields and values, with updates or additions incorporated as necessary. Fields not mentioned in 'input' should remain unchanged.

5. Return the updated 'initData' as a JSON object with the following structure:
   - patientData: {lastName: 'last name', firstName: 'first name', dob: 'yyyy-mm-dd', address1: 'address line 1', address2: 'address line 2', suburb: 'suburb', postcode: 'postcode', contact: 'mobile phone'}.
   - allergies: [{id: null, substance: 'allergen', severity: 'reaction severity'}].
   - medications: [{id: null, name: 'name', dose: 'dose', unit: 'unit', form: 'form', frequency: 'frequency'}].
   - activeHistory: [{id: null, date: 'yyyy-mm-dd', condition: 'condition', details: [{date: 'yyyy-mm-dd', journey: 'details'}]}].
   - pastHistory: [{id: null, date: 'yyyy-mm-dd', condition: 'condition', details: [{date: 'yyyy-mm-dd', journey: 'details'}]}].
   - immunisations: [{id: null, vaccine: 'vaccine', date: 'yyyy-mm-dd'}].
   - familyHistory: [{id: null, relation: 'relation', condition: 'condition', notes: 'notes'}].
   - emergency: [{id: null, name: 'name', relationship: 'relationship', phone: 'phone'}].
   - smoking: {id: null, status: 'status', notes: 'notes'}.
   - alcohol: {id: null, status: 'status', notes: 'notes'}.
   - substance: {id: null, status: 'status', notes: 'notes'}.

### Additional Instructions:
- For dates, use "yyyy-mm-dd" format. If only a year is provided, use "yyyy-01-01". Leave invalid dates as an empty string "".
- Retain all existing data in 'initData' unless specifically updated or removed by information in 'input'.
- Format your response as a flat JSON object without adding any extra summaries or comments.
`;

// const instructionTextToMHCP = `
//     You are a medical practitioner tasked with creating a structured report based on provided data. Use British spelling throughout.

//         Data Types Provided:
//         1. 'input': Text input containing new medical notes.
//         2. 'initData': A JSON array where each object represents an existing medical problem with the following fields:
//            mhcp : 
//            - presentingIssue: Overview of the patient's current mental health issues.
//            - history: Details of the current mental health presentation's history.
//            - pastHistory: Past medical and psychiatric history.
//            - childhoodHistory: Childhood trauma, abuse, learning difficulties, school bullying, ADHD, Autism, ODD, or childhood consultations/medications.
//            - familyHistory: Family mental health history, including depression, anxiety, bipolar disorder, OCD, autism, ADHD, or learning difficulties.
//            - parentChildNeed: Special needs related to parenting or childcare.
//            - occupation: Employment history, covering current/past jobs, durations, and current employment status.
//            - social: Living situation, including household members, housing status, and relationships with family/friends.
//            - substance: Substance use history, including smoking, alcohol, and drugs (ice, cannabis, ecstasy, heroin, cocaine, methamphetamine).
//            - currentMedication: Current medications.
//            - allergies: Known allergies.
//            - treatmentHistory: Past consultations, emergency visits, or hospitalisations.
//            - medicationHistory: Previous medications for mental health issues.
//            - relevantExamination: Key findings from physical examinations.
//            - relevantInvestigation: Investigations (e.g., blood tests, imaging, diagnostics).
//            - devTesting: Past psychological or developmental tests (e.g., IQ or cognitive testing).
//            - mentalStateExamination: Complete the mental state exam, using the following defaults if not provided:
//                * Appearance and Behaviour: Good posture, eye contact, appropriate attire
//                * Speech: Normal rate, normal volume
//                * Mood: Normal
//                * Affect: Congruent
//                * Thought Content: No delusions or suicidal ideation
//                * Thought Form: Normal
//                * Perception: No hallucinations
//                * Cognition: Not assessed, no memory issues
//                * Insight: Good
//            - risk: Assessment of suicidality, perceptual disturbances, delusions, hallucinations, substance and alcohol use.
//            - tools: Any assessment tools used (e.g., K10, DASS21, AQ score, ASRS, PCL-5, ACE).
//            - diagnosis: Diagnosis based on available information, without assumptions.
//            mhformulation :
//            - predisposing: Document any risk factors that increase the likelihood of the current issue. These may include family history of mental health conditions, past trauma, genetic vulnerabilities, personality traits, or chronic physical health conditions.
//            - precipitating: Note recent events or stressors that have triggered or worsened the symptoms. Examples include major life changes, financial hardships, job loss, relationship issues, or recent illnesses or injuries.
//            - perpetuating: Identify factors that sustain or worsen the current issue. These may include ongoing stressors, maladaptive coping strategies, lack of social support, chronic medical issues, or environmental factors that prevent recovery.
//            - protective: Describe the patient's strengths, skills, or resources that could serve as protective factors. These may include social support networks, problem-solving skills, access to therapy, hobbies, coping mechanisms, or a stable living environment.
//            mhsafety : 
//            - reason: The patient's personal motivations or meaningful connections that give them reasons to continue living.
//            - cando: Practical actions the patient can take to ensure their own safety and well-being.
//            - help: Resources and support systems available to the patient that can provide assistance when needed.
//            - call: Trusted individuals the patient can contact for immediate support during difficult times.
//            - talk: People the patient can turn to for emotional support and open communication about their feelings.
//            - professional: Access to professional help.
//            relapsePrevention : 
//            - triggers: Specific situations, environments, or stressors that might lead to a recurrence of the patient's mental health symptoms. Identifying these helps the patient and their support network manage and avoid potential relapse situations.
//            - warningSigns: Early indicators that a relapse may be occurring or that the patient's mental health is beginning to deteriorate. Recognising these signs allows for timely intervention before symptoms become severe.
//            - copingStrategy: Techniques or practices the patient uses to manage their mental health symptoms and stressors. Effective coping strategies enable patients to maintain stability and manage symptoms independently.
//            - support: Any sources of assistance available to the patient, both social and professional, that contribute to their overall wellbeing. Support networks provide help during difficult times and enhance the patient's resilience.
//            - emergency: Actions to be taken immediately in case of a relapse, ensuring the patient's safety and facilitating access to necessary support quickly.

//         Instructions:
//         - Use data from the ‘input’ to update each corresponding field in ‘initData’. Skip fields if no relevant information is provided.
//         - Return a JSON object structured as follows, using "" for any empty fields:
//            - presentingIssue
//            - history
//            - pastHistory
//            - childhoodHistory
//            - familyHistory
//            - parentChildNeed
//            - occupation
//            - social
//            - substance
//            - currentMedication
//            - allergies
//            - treatmentHistory
//            - medicationHistory
//            - relevantExamination
//            - relevantInvestigation
//            - devTesting
//            - mentalStateExamination
//            - risk
//            - tools
//            - diagnosis
//            - predisposing
//            - precipitating
//            - perpetuating
//            - protective
//            - reason
//            - cando
//            - help
//            - call
//            - talk
//            - professional
//            - triggers
//            - warningSigns
//            - copingStrategy
//            - support
//            - emergency

//         Data Handling:
//         - For any field without data, return it as empty string "".
//         - Populate the ‘problems’ field by identifying any issues from other fields.
//         - Retain all existing data in 'initData' unless specifically updated or removed by information in 'input'.
//         - Exclude any summaries or additional comments.

// `;

// const instructionTextToHA = `
// You are a medical practitioner tasked with creating a structured report based on provided data. Use British spelling throughout.

//     Data Types Provided:
//     1. 'input': Text input containing new medical notes.
//     2. 'initData': A JSON array where each object represents an existing medical problem with the following fields:
//     - medicalhistory: Describe the patient's current medical issues, each on separate line, with short detail
//                     Format : 
//                    - medical condition #1 : short detail
//                    - medical condition #2 : short detail
//                    Default is "No current issue"
//     Default is "No current medical issues reported."
//     - pastmedical: List the patient's past medical conditions, each on a separate line, with short detail
//                    Format : 
//                    - medical condition #1 : short detail
//                    - medical condition #2 : short detail
//                    Default is "None."
//     - allergies: Include all known allergies and reactions, with each entry on a separate line. Default is "No known allergies."
//     - familyhistory: Detail the patient's family medical history. Default is "None."
//     - alcohol: Document the patient's alcohol consumption habits. Default is "No alcohol consumption reported."
//     - smoking: Provide the patient's smoking history. Default is "No smoking history reported."
//     - substance: Include details of any substance use (e.g., cannabis, heroin, cocaine, etc.). Default is "No substance use reported."
//     - medications: List current medications and dosages, each on a separate line. 
//                    Format : 
//                    - medication #1
//                    - medication #2
//                    Default is "No current medications."
//     - medicationarrangement: Information about the prescription provider, pharmacy, use of Webster packs, and the last community pharmacy review. Default is "No specific medication arrangement details provided."
//     - vision: Include any issues related to vision, such as glasses, surgeries, or visual aids. Default is "No vision issues reported."
//     - hearing: Document the patient's hearing status, including the use of hearing aids. Default is "No hearing issues reported."
//     - fallandhomesafety: Record any falls in the past 12 months, use of safety aids like grab rails, and personal safety alarms. Default is "No falls or safety concerns reported."
//     - skin: Describe any skin-related conditions. Default is "No skin conditions reported."
//     - feet: Document any foot-related issues, including toenail problems, gait, footwear, and podiatrist visits. Default is "No foot issues reported."
//     - nutrition: Detail the patient's nutritional habits, including meal preparation, portion sizes, and use of dentures or PEG. Default is "No nutritional issues reported."
//     - oralhealth: Provide information on dental health, including the use of dentures, implants, and any oral conditions. Default is "No oral health issues reported."
//     - cognition: Address any cognitive issues, such as memory, comprehension, or processing speed. Default is "No cognitive issues reported."
//     - mentalhealth: Record any mental health concerns, including mood disturbances, delusions, or suicidal thoughts. Default is "No mental health issues reported."
//     - urineContinence: Document urinary continence status, use of pads, and any impact on daily life. Default is "No urinary continence issues reported."
//     - faecalContinence: Record fecal continence status, use of pads, and any impact on daily life. Default is "No fecal continence issues reported."
//     - mobility: Describe the patient's mobility status, including the use of aids and access to community travel. Default is "No mobility issues reported."
//     - activity: List the patient's hobbies, social activities, and club memberships. Default is "No activities or hobbies reported."
//     - social: Provide details on the patient's social support network, including friends, family, and social groups. Default is "No social support network reported."
//     - housing: Describe the patient's living arrangements (e.g., own home, residential facility). Default is "No specific housing arrangements reported."
//     - legal: Document any legal matters, including wills and power of attorney. Default is "No legal issues reported."
//     - finance: Provide information on how the patient's finances are managed, including decision-makers. Default is "No financial management issues reported."
//     - driving: Detail the patient's driving status, including any assessments and use of alternative transportation. Default is "No driving issues reported."
//     - advancedcaredirective: Include information on the existence and details of an advanced care directive. Default is "No advanced care directive reported."
//     - teamcare: List the healthcare team involved in the patient's care, including specialists and allied health professionals. Default is "No healthcare team reported."
//     - services: Document any external services involved in the patient's care (e.g., aged care, community services). Default is "No external services involved."
//     - examination: Provide details of any recent medical examinations. Default is "No recent examinations reported."
//     - surveillance: Record any future surveillance plans (e.g., cancer screenings). Default is "No surveillance plans reported."
//     - vaccination: List any vaccinations that have been administered or are pending. Default is "No vaccinations reported."


//     Instructions:
//     - Use data from the ‘input’ to update each corresponding field in ‘initData’. Skip fields if no relevant information is provided.
//     - Return a JSON object structured as follows, using "" for any empty fields:
//         - medicalhistory
//         - pastmedical
//         - allergies
//         - familyhistory
//         - alcohol
//         - smoking
//         - substance
//         - medications
//         - medicationarrangement
//         - vision
//         - hearing
//         - fallandhomesafety
//         - skin
//         - feet
//         - nutrition
//         - oralhealth
//         - cognition
//         - mentalhealth
//         - urineContinence
//         - faecalContinence
//         - mobility
//         - activity
//         - social
//         - housing
//         - legal
//         - finance
//         - driving
//         - advancedcaredirective
//         - teamcare
//         - services
//         - examination
//         - surveillance
//         - vaccination
//     Data Handling :
//     - For any field without data, return it as "".
//     - Exclude any summaries or additional comments.

// `;

const instructionTextToMHCP = `
You are a medical practitioner tasked with creating a structured report based on provided data. Use British spelling throughout.

### Data Types Provided:
1. **"input"**: Text input containing new mental health notes.
2. **"initData"**: A JSON object where each field represents an aspect of the patient"s mental health care plan. The fields include:

#### Mental Health Care Plan (mhcp):
- **presentingIssue**: Brief overview of the patient"s current mental health issues. eg. Depression for the past 3 months
- **history**: Details of the current mental health presentation's history. eg. Patient has history of depression the past 3 months, with headache, panic attacks, etc.
- **pastHistory**: Past medical and psychiatric history. List each condition on a new line, e.g.:
  - Depression
  - PTSD
- **childhoodHistory**: Childhood trauma, abuse, learning difficulties, school bullying, ADHD, Autism, ODD, or childhood consultations/medications.
- **familyHistory**: Family mental health history, including depression, anxiety, bipolar disorder, OCD, autism, ADHD, or learning difficulties.
- **parentChildNeed**: Special needs related to parenting or childcare.
- **occupation**: Employment history, covering current/past jobs, durations, and current employment status.
- **social**: Living situation, including household members, housing status, and relationships with family/friends.
- **substance**: Substance use history, including smoking, alcohol, and drugs (e.g., ice, cannabis, ecstasy, heroin, cocaine, methamphetamine).
- **currentMedication**: Current medications.
- **allergies**: Known allergies.
- **treatmentHistory**: Past consultations, emergency visits, or hospitalisations.
- **medicationHistory**: Previous medications for mental health issues.
- **relevantExamination**: Key findings from physical examinations.
- **relevantInvestigation**: Investigations (e.g., blood tests, imaging, diagnostics).
- **devTesting**: Past psychological or developmental tests (e.g., IQ or cognitive testing).
- **mentalStateExamination**: Complete mental state examination. If no information is provided, leave it empty. If information is provided, update the mentioned sections only and maintain the template order. Use the following template for formatting, with each section on a new line:
  - **Appearance and Behaviour**: Good posture, eye contact, appropriate attire.
  - **Speech**: Normal rate, normal volume.
  - **Mood**: Normal.
  - **Affect**: Congruent.
  - **Thought Content**: No delusions or suicidal ideation.
  - **Thought Form**: Normal.
  - **Perception**: No hallucinations.
  - **Cognition**: Not assessed, no memory issues.
  - **Insight**: Good.
- **risk**: Assessment of suicidality, perceptual disturbances, delusions, hallucinations, substance and alcohol use.
- **tools**: Any assessment tools used (e.g., K10, DASS21, AQ score, ASRS, PCL-5, ACE).
- **diagnosis**: Diagnosis based on available information. List each diagnosis on a new line, e.g.:
  - Depression
  - PTSD

#### Mental Health Formulation (mhformulation):
- **predisposing**: Risk factors increasing the likelihood of the current issue (e.g., family history, trauma, genetic vulnerabilities).
- **precipitating**: Recent events or stressors triggering or worsening symptoms (e.g., life changes, financial hardships, job loss).
- **perpetuating**: Factors sustaining or worsening the issue (e.g., ongoing stressors, lack of support, chronic medical issues).
- **protective**: Patient strengths, skills, or resources serving as protective factors (e.g., social support, coping mechanisms).

#### Mental Health Safety Plan (mhsafety):
- **reason**: Personal motivations or meaningful connections for living. 
- **cando**: Practical actions the patient can take for safety and well-being.
- **help**: Resources and support systems available to the patient.
- **call**: Trusted individuals the patient can contact during crises.
- **talk**: People providing emotional support.
- **professional**: Access to professional help.

#### Relapse Prevention Plan (relapsePrevention):
- **triggers**: Situations or stressors that might lead to relapse.
- **warningSigns**: Early indicators of potential relapse.
- **copingStrategy**: Techniques to manage mental health symptoms and stressors.
- **support**: Social and professional sources of assistance.
- **emergency**: Actions to ensure safety during a relapse.

### Instructions:
1. **Update Fields**:
   - Use data from **"input"** to update corresponding fields in **"initData"**.
   - For **mentalStateExamination**:
     - Leave it empty if no information is provided in the input.
     - Update only the mentioned sections while retaining defaults for all others, keeping the order consistent with the template.
     - Write each section on a new line, e.g.:
       - Mood: Normal
       - Speech: Slow rate, low volume
   - For **diagnosis** and **pastHistory**:
     - List each item on a new line using a bullet point format.

2. **Default Handling**:
   - For fields without data in both **"input"** and **"initData"**, use the specified defaults (e.g., "No known allergies").
   - Do not infer or assume information not provided in the input.

3. **Output Format**:
   Return a JSON object with the following structure:
   - **presentingIssue**
   - **history**
   - **pastHistory**
   - **childhoodHistory**
   - **familyHistory**
   - **parentChildNeed**
   - **occupation**
   - **social**
   - **substance**
   - **currentMedication**
   - **allergies**
   - **treatmentHistory**
   - **medicationHistory**
   - **relevantExamination**
   - **relevantInvestigation**
   - **devTesting**
   - **mentalStateExamination**
   - **risk**
   - **tools**
   - **diagnosis**
   - **predisposing**
   - **precipitating**
   - **perpetuating**
   - **protective**
   - **reason**
   - **cando**
   - **help**
   - **call**
   - **talk**
   - **professional**
   - **triggers**
   - **warningSigns**
   - **copingStrategy**
   - **support**
   - **emergency**

4. **Data Handling**:
   - Exclude summaries or additional comments.
   - Retain existing data in **"initData"** unless specifically updated or removed by **"input"**.
   - For **mentalStateExamination**, adjust only the mentioned fields from the input, keeping all others in template order.

### Example:
**Input**:
"Patient reports slow rate, low volume speech. Diagnosed with Depression and PTSD."

**initData**:
{
  "mentalStateExamination": "",
  "diagnosis": "",
  "pastHistory": ""
}
`;


const instructionTextToHA = `
You are a medical practitioner tasked with creating a structured report based on provided data. Use British spelling throughout.

### Data Types Provided:
1. **'input'**: Text input containing new medical notes.
2. **'initData'**: A JSON object where each key represents an existing medical field (e.g., 'medicalhistory', 'pastmedical').

### Instructions:
1. Use data from **'input'** to update the corresponding fields in **'initData'**:
   - If relevant information for a field is present in 'input', update that field in 'initData' with the new value.
   - If a field in 'initData' has no data (empty or missing), and there is no relevant information in 'input', use the default value provided for that field.
   - Fields that are not mentioned in 'input' should retain their current values in 'initData'.

2. **Default Values**:
   For any field without data in both 'input' and 'initData', use the following defaults:
   - **medicalhistory**: "No current medical issues reported."
   - **pastmedical**: "None."
   - **allergies**: "No known allergies."
   - **familyhistory**: "None."
   - **alcohol**: "No alcohol consumption reported."
   - **smoking**: "No smoking history reported."
   - **substance**: "No substance use reported."
   - **medications**: "No current medications."
   - **medicationarrangement**: "No specific medication arrangement details provided."
   - **vision**: "No vision issues reported."
   - **hearing**: "No hearing issues reported."
   - **fallandhomesafety**: "No falls or safety concerns reported."
   - **skin**: "No skin conditions reported."
   - **feet**: "No foot issues reported."
   - **nutrition**: "No nutritional issues reported."
   - **oralhealth**: "No oral health issues reported."
   - **cognition**: "No cognitive issues reported."
   - **mentalhealth**: "No mental health issues reported."
   - **urineContinence**: "No urinary continence issues reported."
   - **faecalContinence**: "No faecal continence issues reported."
   - **mobility**: "No mobility issues reported."
   - **activity**: "No activities or hobbies reported."
   - **social**: "No social support network reported."
   - **housing**: "No specific housing arrangements reported."
   - **legal**: "No legal issues reported."
   - **finance**: "No financial management issues reported."
   - **driving**: "No driving issues reported."
   - **advancedcaredirective**: "No advanced care directive reported."
   - **teamcare**: "No healthcare team reported."
   - **services**: "No external services involved."
   - **examination**: "No recent examinations reported."
   - **surveillance**: "No surveillance plans reported."
   - **vaccination**: "No vaccinations reported."

3. **Return Structure**:
   Return a JSON object where each field from **'initData'** is updated as necessary based on 'input'. Fields without updates or input should contain either their existing value or the default value if empty.

4. **Output Example**:
   - **Input**:
     - "Patient reports moderate alcohol consumption and takes paracetamol daily."
   - **initData**:
     - {
         "alcohol": "",
         "smoking": "",
         "medications": "",
         "mentalhealth": ""
       }
   - **Output**:
     - {
         "alcohol": "Moderate alcohol consumption.",
         "smoking": "No smoking history reported.",
         "medications": "Paracetamol daily.",
         "mentalhealth": "No mental health issues reported."
       }

5. **Data Handling**:
   - For fields without data in **'input'**, retain the existing value in **'initData'** or assign the default if no value exists.
   - Do not include summaries, additional comments, or fields not listed in the provided structure.

6. **Key Rules**:
   - Always ensure the output is consistent with the structure of **'initData'**.
   - Use the exact default values when applicable.
   - Use british spelling.
   - Avoid duplications or adding unrelated information.
`;


// const instructionTextToPlan = `
// You are a medical practitioner.

// Use British spelling.

// You will receive three types of data:
// 1. 'input': A text containing medical notes.
// 2. 'initData': A JSON array of objects, each representing an existing medical problem. Each object has fields for:
//    - "problem": The title of the problem.
//    - "goal": A list of goals for the problem.
//    - "plan": A list of tasks for the problem.
//    - "review": A list of review notes for the problem.
//    - "journey": A list tracking the patient's journey for this problem.
//    - "category": Category of this problem.
// 3. 'references' (optional): A JSON array containing reference data for medical problems, with each entry containing fields for "problem", "goal", and "todo" (tasks).

// ### Task:

// 1. Read through the notes provided in 'input'.
// 2. Identify and create a list of medical problems based on the content in 'input'.
// 3. For each identified problem in 'input', check if it exists in 'initData'.

// 4. If the identified problem does not exist in 'initData':
//    - **If 'references' is available**: Check if the problem exists in 'references'. If found, use the title, goals, and todo list from 'references'. 
//    - **If 'references' is unavailable or the problem is not found in 'references'**: Create a new title based on the problem description from 'input'.
//    - Do not add, change or rewrite any content not explicitly present in the input.
//    - Add a new problem entry in 'initData' with the following fields:
//      - "problem": Use the title from 'references' if available; otherwise, use a title based on the problem description from 'input'.
//      - "goal": Use goals from 'references' if available. Add any additional goals from 'input' in this format: '[{id: null, description: "goal description", dateAdded: "yyyy-mm-dd" (today's date)}]'. If no data, return as [].
//      - "plan": Use the todo list from 'references' if available. Add additional tasks from 'input' in this format: '[{id: null, entry: "task description", status: "plan", dateAdded: "yyyy-mm-dd" (today's date)}]'.If no data, return as [].
//      - "review": Add a single review entry in the format: '[{id: null, date: "yyyy-mm-dd" (today's date), notes: "note about the problem"}]'. If no data, return as [].
//      - "journey": Add a single journey entry in the format: '[{id: null, date: "yyyy-mm-dd" (today’s date or as mentioned), journey: "note about the journey", type: "either diagnosis, pathology, imaging, medication, procedure, expertise"}]'. If no data, return as [].
//        - Use 'pathology' for blood test results.
//        - Use 'imaging' for imaging results (X-ray, MRI, CT-scan, etc.).
//        - Use 'medication' for new or changed medications.
//        - Use 'procedure' for surgical or endoscopic procedures.
//        - Use 'expertise' for reports from allied health or specialists.
//      - "priority": Set to "Low".
//      - "collapsed": Set to 'false'.
//      - "category": Set to "Chronic Disease" if related with chronic disease such as Diabetes, Coeliac disease, Hypertenion, etc.,  "Lifestyle" if related with smoking, alcohol, exercise, diet, any substance use, "Mental Health" if related with any mental health diagnosis such as depression, anxiety, bipolar, etc., "Prevention" if related with cancer screening, vaccination, surveillance, "Social" if related with social issue such as legal, financial, employment, driving, social support, etc., "Symptoms" if it shows undiagnosed symptoms such as chest pain, abdominal pain, headache, iron deficiency, B12 deficiency, fever, etc.

// 5. If the identified problem already exists in 'initData', update the existing entry as follows:
//    - **Goals**: Add any new goals from 'input' in the format: '[{id: null, description: "goal description"}]'.
//    - **Plan**: Add any new tasks from 'input' in the format: '[{id: null, entry: "task description", status: "plan"}]'.
//      - If a task matches an existing entry in "plan", update its status based on the input:
//        - Set 'status' to "doing" if the task is ongoing.
//        - Set 'status' to "done" if the task is completed.
//    - **Review**: Append any additional review notes from 'input' in the format: '[{id: null, date: "yyyy-mm-dd" (today’s date), notes: "review note"}]'.
//    - **Journey**: Append any new journey entries from 'input' in the format: '[{id: null, date: "yyyy-mm-dd" (date mentioned or today’s date), journey: "journey note", type: "diagnosis, pathology, imaging, medication, procedure, expertise"}]'.

//    Retain all existing data in 'initData' unless specifically updated or removed by information in 'input'.
// Return the updated 'initData' as a JSON array of objects.

// Do not add any comments or explanations; only output the JSON array.
// `;

const instructionTextToPlan = `
You are a medical practitioner.

Use British spelling.

You will receive three types of data:
1. **'input'**: A text containing medical notes.
2. **'initData'**: A JSON array of objects, each representing an existing medical problem. Each object has fields for:
   - **"problem"**: The title of the problem.
   - **"goal"**: A list of goals for the problem.
   - **"plan"**: A list of tasks for the problem.
   - **"review"**: A list of review notes for the problem.
   - **"journey"**: A list tracking the patient's journey for this problem.
   - **"category"**: The category of this problem.
3. **'references' (optional)**: A JSON array containing reference data for medical problems, with each entry containing fields for "problem", "goal", and "todo" (tasks).

### Task:

1. **Read through the notes provided in 'input'.**
2. **Identify and create a list of medical problems based on the content in 'input'.**
3. **For each identified problem in 'input', check if it exists in 'initData':**
   - **If the problem does not exist in 'initData':**
     - If 'references' is available and contains the problem, use the title, goals, and todo list from 'references'.
     - If 'references' is unavailable or the problem is not found in 'references', use a title based on the problem description from 'input'.
     - **Do not create any goal or plan unless explicitly mentioned in 'input' or 'references'.**
     - Add a new problem entry to 'initData' with the following fields:
       - **"problem"**: Use the title from 'references' if available; otherwise, use a title based on the problem description from 'input'.
       - **"goal"**: Use goals from 'references' if available. Add additional goals from 'input' in this format: 
         \`[{id: null, description: "goal description", dateAdded: "yyyy-mm-dd" (today's date)}]\`.
         If no goals are mentioned, return as an empty array \`[]\`.
       - **"plan"**: Use the todo list from 'references' if available. Add additional tasks from 'input' in this format: 
         \`[{id: null, entry: "task description", status: "plan", dateAdded: "yyyy-mm-dd" (today's date)}]\`.
         If no tasks are mentioned, return as an empty array \`[]\`.
       - **"review"**: Add a single review entry in the format: 
         \`[{id: null, date: "yyyy-mm-dd" (today's date), notes: "note about the problem"}]\`.
         If no review is mentioned, return as an empty array \`[]\`.
       - **"journey"**: Add a single journey entry in the format: 
         \`[{id: null, date: "yyyy-mm-dd" (today's date or as mentioned), journey: "note about the journey", type: "either diagnosis, pathology, imaging, medication, procedure, expertise"}]\`.
         - Use 'pathology' for blood test results.
         - Use 'imaging' for imaging results (X-ray, MRI, CT scan, etc.).
         - Use 'medication' for new or changed medications.
         - Use 'procedure' for surgical or endoscopic procedures.
         - Use 'expertise' for reports from allied health or specialists.
         If no journey is mentioned, return as an empty array \`[]\`.
       - **"priority"**: Set to "Low".
       - **"collapsed"**: Set to 'false'.
       - **"category"**: Assign based on the nature of the problem:
         - "Chronic Disease" if related to chronic diseases (e.g., Diabetes, Coeliac disease, Hypertension).
         - "Lifestyle" if related to smoking, alcohol, exercise, diet, or any substance use.
         - "Mental Health" if related to mental health diagnoses (e.g., depression, anxiety, bipolar).
         - "Prevention" if related to cancer screening, vaccination, or surveillance.
         - "Social" if related to social issues (e.g., legal, financial, employment, driving, social support).
         - "Symptoms" if it describes undiagnosed symptoms (e.g., chest pain, abdominal pain, headache, iron deficiency, B12 deficiency, fever).

4. **If the identified problem already exists in 'initData', update the existing entry as follows:**
   - **Goals**: Add only new goals mentioned in 'input' in this format: 
     \`[{id: null, description: "goal description"}]\`.
     - Do not create or modify goals unless explicitly mentioned in 'input'.
   - **Plan**: Add only new tasks mentioned in 'input' in this format: 
     \`[{id: null, entry: "task description", status: "plan"}]\`.
     - If a task matches an existing entry, update its status based on the input:
       - Set 'status' to "doing" if the task is ongoing.
       - Set 'status' to "done" if the task is completed.
     - Do not create or modify tasks unless explicitly mentioned in 'input'.
   - **Review**: Append any new review notes from 'input' in this format:
     \`[{id: null, date: "yyyy-mm-dd" (today's date), notes: "review note"}]\`.
   - **Journey**: Append any new journey entries from 'input' in this format:
     \`[{id: null, date: "yyyy-mm-dd" (date mentioned or today's date), journey: "journey note", type: "diagnosis, pathology, imaging, medication, procedure, expertise"}]\`.
   - Retain all existing data in 'initData' unless specifically updated or removed by 'input'.

5. **Data Integrity**:
   - Retain all existing problems in 'initData' unless a problem is explicitly removed or overwritten by 'input'.
   - Do not overwrite existing problems when new input is added.

6. **Output**:
   - Return the updated 'initData' as a JSON array of objects.
   - Do not add any comments or explanations; only output the JSON array.
`;


const instructionTextToReview = `
You are a medical practitioner tasked with writing simplified medical notes from the provided input. Follow these guidelines:

- Use short sentences and simple points.
- Organise the notes in this specific section:
  1. History: Summarise key points of the patient's history concisely.
  2. Examination: Include details of any physical or mental state examination conducted, if applicable.
  3. Assessment: List any identified problems, issues, or diagnoses.
  4. Management: Outline any plans or actions to be taken, if applicable.
- Write the content directly underneath each section. 
- Separate each section with one empty line.
- Skip any section if no relevant content is provided for it. Do not add anything beyond the input.
- Format the output as a flat JSON object in the following structure:
{
  "review": "medical notes above",
  "tag": [
    "list of tags related to the content, can be more than one",
    "Mental Health" (if related to mental health issues),
    "Chronic Disease" (if related to chronic diseases such as Diabetes, Coeliac disease, Hypertension, etc.),
    "Symptoms" (if related to symptoms such as chest pain, knee pain, abdominal pain, etc.),
    "Lifestyle" (if related to smoking, alcohol, caffeine intake, physical activities, diet, or nutrition),
    "Prevention" (if related to vaccination, cancer screening, surveillance such as repeat colonoscopy, repeat CT scan of lungs, etc.),
    "Social" (if related to social issues such as family relationships, social support, occupation, legal matters, finance, driving, or daily activities)
  ]
}

Additional Guidelines:
- Conciseness: Keep the notes as concise and direct as possible.
- Relevance: Include only information provided in the input. Do not infer or add extra content.
- Tagging: Ensure the appropriate tags are applied based on the content. Tags can be more than one if applicable.
- Formatting: If a section is skipped due to lack of content, ensure it is omitted from the notes entirely.
`;

const instructionTextToReferrence = `
You are a medical practitioner. 
Your task is to identify and return a list of problems from the input. 
Each problem should be a separate entry in the JSON array. 
Ensure that British spelling is used throughout. 
Return the response strictly as a JSON array without any comments or additional text outside of the JSON array. 
Each problem entry should follow this structure:
[
    {
        "id": null,
        "problem": "Problem description",
        "category": "Either Chronic Disease, Mental Health, Lifestyle, Preventive, or Social",
        "goal": [
            {
                "id": null,
                "entry": "Goal description if applicable"
            }
        ],
        "plan": [
            {
                "id": null,
                "entry": "Plan description if applicable",
                "status": "plan"
            }
        ],
        "education": [
            {
                "id": null,
                "entry": "Education title",
                "link": "url link of the education"
            }
        ]
    }
]

Make sure each problem is distinct and avoid combining multiple problems into a single entry.
`;

const instructionTextToContact = `
You are a medical receptionist. 
Your task is to read the provided text, which contains contact details. 
Each contact detail should be a separate entry in the JSON array.

Ensure that British spelling is used throughout. 
Return the response strictly as a JSON array without any comments or additional text outside of the JSON array. 

Each contact entry should follow this structure:

[
    {
        "id": "",
        "title": "",
        "firstName": "",
        "lastName": "",
        "profession": "",
        "providerNumber": "",
        "address1": "",
        "address2": "",
        "suburb": "",
        "postcode": "",
        "state": "",
        "phone": "",
        "fax": "",
        "email": ""
    }
]

Accepted titles: ['Mr', 'Mrs', 'Ms', 'Dr']
Australian states: ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT']
Professions include:
- General Practitioner (GP)
- Pediatrician
- Cardiologist
- Dermatologist
- Neurologist
- Oncologist
- Ophthalmologist
- Orthopedic Surgeon
- Psychiatrist
- Radiologist
- Anesthesiologist
- Endocrinologist
- Gastroenterologist
- Hematologist
- Infectious Disease Specialist
- Nephrologist
- Obstetrician/Gynecologist (OB/GYN)
- Pulmonologist
- Rheumatologist
- Urologist
- Surgeon
- Plastic Surgeon
- Emergency Medicine Physician
- Pathologist
- Otolaryngologist (ENT)
- Allergy and Immunology Specialist
- Critical Care Medicine Specialist
- Geriatrician
- Geneticist
- Sports Medicine Specialist
- Podiatrist
- Occupational Medicine Specialist
- Physiatrist (Physical Medicine and Rehabilitation)
- Family Medicine Physician
- Hospitalist
- Medical Geneticist
- Pain Management Specialist
- Sleep Medicine Specialist
- Nuclear Medicine Specialist
- Preventive Medicine Specialist
- Physiotherapist
- Occupational Therapist
- Speech Pathologist
- Dietitian
- Diabetes Educator
- Pharmacist
- Audiologist
- Social Worker
- Chiropractor
- Osteopath
- Exercise Physiologist
- Prosthetist/Orthotist
- Medical Laboratory Scientist
- Medical Imaging Technologist
- Psychologist
- Clinical Psychologist
- Counsellor
- Mental Health Nurse
- Case Manager
- Medical Power of Attorney (MPOA)
- Care Worker
- Aged Care Worker
- Disability Support Worker
- Home Care Assistant
- Personal Care Assistant (PCA)
`;

const instructionWritingLetter = `
You are a medical professional writing referral letter using data from the 'input'.
Combine problems in the paragraphs.
Do not add anything outside of the given input. Use British spelling.
Don't mention the goals. 
Don't add any other comment.
Avoid repetition within the notes.
Return as text only.
`


// Sub-functions
const textToNote = async (text) => {
    var respond = await AITextToText(instructionWritingNote, text);
    console.log(respond)
    return respond;
}

const textToSummary = async (text, formData) => {
    var respond = await AITextToObjectwithData(instructionTextToSummary, text, formData.history);
    return respond; 
}

const textToMHCP = async (text, formData) => {


    const fieldsToKeep = ["mhcp", "mhformulation", "mhsafety", "relapsePrevention" ];
    const MHOnly = Object.keys(formData)
    .filter(key => fieldsToKeep.includes(key))
    .reduce((obj, key) => {
        obj[key] = formData[key];
        return obj;
    }, {});

    // Creating system command
    const data = await AITextToObjectwithData(instructionTextToMHCP, text, MHOnly);
    console.log(data)
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];

    const mhcp = {
        presentingIssue: data.presentingIssue,
        history: data.history,
        pastHistory: data.pastHistory,
        childhoodHistory: data.childhoodHistory,
        familyHistory: data.familyHistory,
        parentChildneed: data.parentChildNeed,
        occupation :data.occupation,
        social: data.social,
        substance : data.substance,
        currentMedication: data.currentMedication,
        allergies : data.allergies,

        treatmentHistory: data.treatmentHistory,
        medicationHistory: data.medicationHistory,

        relevantExamination : data.relevantExamination,
        relevantInvestigation: data.relevantInvestigation,
        devTesting : data.devTesting,


        mentalStateExamination: data.mentalStateExamination,
        risk: data.risk,
        tools: data.tools,
        diagnosis: data.diagnosis,

        date: data.timeStamp ? data.timeStamp : formattedDate
    };
    
    const mhformulation = {
        predisposing: data.predisposing,
        precipitating: data.precipitating,
        perpetuating: data.perpetuating,
        protective: data.protective,
        date: data.timeStamp ? data.timeStamp : formattedDate
    };
    
    const mhsafety = {
        reason: data.reason,
        cando: data.cando,
        help: data.help,
        call: data.call,
        talk: data.talk,
        professional: data.professional,
        date: data.timeStamp ? data.timeStamp : formattedDate
    };

    const relapsePrevention = {
        triggers: data.triggers,
        warningSigns: data.warningSigns,
        copingStrategy: data.copingStrategy,
        support: data.support,
        emergency: data.emergency
    }

    if (mhcp && mhformulation && mhsafety && relapsePrevention) {
        return ({mhcp, mhformulation, mhsafety, relapsePrevention})
    } else {
        console.log("Error")
        return null;
    }
}

const textToHA = async (text, formData) => {

    // Creating system command
    const data = await AITextToObjectwithData(instructionTextToHA, text, formData.healthassessment);
    console.log(data)
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];

    const filledForm = {
        medicalHistory: data.medicalhistory,
        pastMedical: data.pastmedical,
        allergies: data.allergies,
        familyHistory: data.familyhistory,
        alcohol: data.alcohol,
        smoking: data.smoking,
        substance: data.substance,
        medications: data.medications,
        medicationArrangement: data.medicationarrangement,
        vision: data.vision,
        hearing: data.hearing,
        fallAndHomeSafety: data.fallandhomesafety,
        skin: data.skin,
        feet: data.feet,
        nutrition: data.nutrition,
        oralHealth: data.oralhealth,
        cognition: data.cognition,
        mentalHealth: data.mentalhealth,
        urineContinence: data.urineContinence,
        faecalContinence: data.faecalContinence,
        mobility: data.mobility,
        activity: data.activity,
        social: data.social,
        housing: data.housing,
        legal: data.legal,
        finance: data.finance,
        driving: data.driving,
        advancedCareDirective: data.advancedcaredirective,
        services: data.services,
        examination: data.examination,
        surveillance: data.surveillance,
        vaccination: data.vaccination, 
        date: formattedDate
    };

    if (filledForm) {
        return filledForm
    } else {
        console.log("Error")
        return null;
    }
}

const textToPlan = async (input, prevData, referrences) => {
    const data = await AITextToObjectwithDataAndRef(instructionTextToPlan, input, prevData, referrences)
    const plan = regenerateIds(data);
    return plan;
}

const textToReview = async (text) => {
    const review = await AITextToText(instructionTextToReview, text);
    return review;

}

const textToPlanReferrence = async (text) => {
    const data = await AITextToObject(instructionTextToReferrence, text);
    const plan = regenerateRefIds(data);
    return plan;
}

const textToContact = async (text) => {
    const data = await AITextToObject(instructionTextToContact, text);
    const plan = regenerateIds(data);
    return plan;
}

export const textToLetter = async (text) => {
    var respond = await AITextToText(instructionWritingLetter, text);
    return respond;
}

// Workflows

// 1. Text to Consult note
// 2. Text to General Summary
// 3. Text to Health Assessment Form
// 4. Text to Mental Health Care Plan
// 5. Text to Planning
// 6. Text to Reviews

export const workflowAI = async (input, formData, referrences) => {
    var initData = formData;
    const timeStamp = new Date();

    // 1. Text to Consult note

    // Check if consultations exists and is an array; initialise if it doesn't
    if (!Array.isArray(initData.consultations)) {
        initData.consultations = [];
    }
    var consult = await textToNote(input)    

    var jsConsult = JSON.parse(consult)
    jsConsult.date = timeStamp;
    jsConsult.id = generateId();
    initData.consultations.push(jsConsult);

    // 2. Text to General Summary
    if (typeof initData.history !== 'object' || initData.history === null) {
        initData.history = {};
    }
    var genSummary = await textToSummary(input, initData)
    initData.history = genSummary;

    // 3. Text to Health Assessment
    if (typeof initData.healthassessment !== 'object' || initData.healthassessment === null) {
        initData.healthassessment = {};
    }
    var HA = await textToHA(input, initData);
    initData.healthassessment = HA;

    // 4. Text to Mental Health Care Plan
    if (typeof initData.mhcp !== 'object' || initData.mhcp === null) {
        initData.mhcp = {};
    }
    if (typeof initData.mhformulation !== 'object' || initData.mhformulation === null) {
        initData.mhformulation = {};
    }
    if (typeof initData.mhsafety !== 'object' || initData.mhsafety === null) {
        initData.mhsafety = {};
    }
    if (typeof initData.mhrelapse !== 'object' || initData.mhrelapse === null) {
        initData.mhrelapse = {};
    }
    var MH = await textToMHCP(input, initData);
    initData.mhcp = MH.mhcp;
    initData.mhformulation = MH.mhformulation;
    initData.mhsafety = MH.mhsafety;
    initData.relapsePrevention = MH.relapsePrevention;
   
    // 5. Text to Planning
    if (!Array.isArray(initData.planning)) {
        initData.planning = [];
    }
    var planning = await textToPlan(input, initData, referrences);
    initData.planning = planning;

    // log event
    await axios.post(`${EndPoint}/log/event`, { type:'Workflow', credit: 10 }, { withCredentials: true });


    // 6. Text To reviews
    // if (!Array.isArray(initData.reviews)) {
    //     initData.reviews = [];
    // }
    // var review = await textToReview(input, initData.reviews)
    // var jsReview = JSON.parse(review)
    // jsReview.date = timeStamp;
    // jsReview.id = generateId();
    // initData.reviews.push(jsReview);
    
    return initData;
}