import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Planning from '../SharedComponents/Planning';

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}


class CDM extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contacts: [],
            referrences: [],
            formData: this.props.patientData,
            adminData: this.props.adminData,
            isEditing: false,

        };

    }

    componentDidUpdate(prevProps) {
        if (prevProps.patientData !== this.props.patientData) {
            this.setState({ formData: this.props.patientData });
        }
        if (prevProps.adminData !== this.props.adminData) {
            this.setState({ amindData: this.props.adminData });
        }

    }
    

    updatePatientData = () => {
        this.props.updatePatientData(this.state.formData)
    };

    //Edit form functions
    handleToggleEdit = () => {
        this.setState((prevState) => ({
            isEditing: !prevState.isEditing
        }));
    };

    handleInputChange = (key, value) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                planning: {
                    ...prevState.formData.planning,
                    [key]: value
                }
            }
        }));
    };
    


    ////// UPDATE FROM CHILD COMPONENT 

    // Function to update problems from child component
    handleProblemsUpdate = (updatedProblems) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                planning: updatedProblems
            },
        }), this.updatePatientData);
    };

    handleReviewUpdate = (updatedReviews) => {
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            mhreviews: updatedReviews,
          },
        }), this.updatePatientData);
    };
    
    // update message
    updateMessage = (message) => {
        this.setState({message: message})
    }

    render() {

        const { formData, activeTab } = this.state;

        return (
            <div  className="col-span-6 grid-rows-4 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-3" style={{ gridTemplateRows: ' minmax(12px, auto) minmax(12px, auto) minmax(320px, auto)' }}>
              
                <div className='text-center col-span-6 text-3xl font-bold py-4 text-sky-900'>Chronic Disease Management Plan</div>
                <Planning problems={formData.planning} onProblemsUpdate={this.handleProblemsUpdate} category="Chronic Disease"/>

                <ToastContainer />
                
            </div>
        );
    }

}

export default CDM;
