import React, { Component } from 'react';

class FailedPayment extends Component {
  render() {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-red-100">
        <h1 className="text-2xl font-bold text-red-700 mb-4">Payment Failed</h1>
        <p className="text-gray-700 mb-6">Something went wrong while processing your payment. Please try again.</p>
        <button
          onClick={() => alert('Retrying payment')}
          className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
        >
          Retry Payment
        </button>
      </div>
    );
  }
}

export default FailedPayment;
