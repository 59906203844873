import React from 'react';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString(); // Months are zero-based
    let year = date.getFullYear();
  
    // Add leading zero to day and month if they are less than 10
    day = day.length < 2 ? '0' + day : day;
    month = month.length < 2 ? '0' + month : month;
  
    return `${day}/${month}/${year}`;
  };

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class Comment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                comment: "",
                replyTo: ""
            },
            commentData : [],
            isSubmitted: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    componentDidMount() {
        this.fetchCommentData();
    }

    componentDidUpdate(prevState) {

        if (this.state.isSubmitted && !prevState.isSubmitted) {
            this.fetchCommentData();
            this.setState({ isSubmitted: false });
        }
    }


    fetchCommentData = async () => {
        try {
          const response = await axios.get(`${EndPoint}/comments/user`, {
            withCredentials: true,
          });
          this.setState({ commentData: response.data });
        } catch (error) {
          console.error('Error fetching comments:', error);
          // Handle errors here
        }
      };

    handleChange(event) {
        const { name, value } = event.target;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    }

    handleSubmit() {
        const { formData } = this.state;
        const updatedFormData = {
            ...formData,
            replyTo: "",
            // date: new Date() // Consider setting this on the server instead
        };
    
        axios.post(`${EndPoint}/comments`, updatedFormData, {withCredentials: true})
        .then(response => {
            toast.success(`Comment added. Thank you !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
            this.handleClear();
            this.setState({ isSubmitted: true });
        })
        .catch(error => {
            toast.error(`Error posting comment. Please try again`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
            });
        });
    }
    
    handleClear() {
        this.setState({
            formData: {
                comment: "", 
                replyTo: ""
            }
        });
    }

    renderReplies(id) {
        var replies = this.state.commentData;
        var filteredReplies = replies.filter(item => item.replyTo !== "" && !item.subReplyTo);
        return filteredReplies.map((item, index) => {
            if (item.replyTo === id ) {
                return (
                    <div className='ml-6 rounded shadow-lg bg-sky-500 p-1 m-1 w-[90%]' key={index}>
                        <div className='text-xs'>{item.comment}</div>
                        <div className='text-xs py-1'>{formatDate(item.date)}</div>
                        {item.replyTo}  by {item.subReplyTo}
                    </div>
                )
            }
        })
    }

    renderComments() {
        var comments = this.state.commentData;
    
        return comments.map((item, index) => {
            if (!item.replyTo) {
                return (
                    <div className='my-4' key={index}>
                    <div className='rounded shadow-lg bg-slate-100 p-1 m-1 w-[90%]' >
                        <div className='text-xs'>{item.comment}</div>
                        <div className='text-xs py-1'>{formatDate(item.date)}</div>
                    </div>
                       {this.renderReplies(item._id)}
                    </div>
                )
            } 
        
        })
    }

    renderForm() {

        var formData = this.state.formData;
        return (
            <div>
       
      
            <textarea
            name="comment"
            value={formData.comment}
            onChange={this.handleChange}
            className="p-1 my-4 text-sm h-[200px] w-full resize-none rounded"
            />
            <button className='bg-sky-200 hover:bg-sky-300 text-black p-2 w-full my-2 rounded shadow-lg' onClick={this.handleSubmit}>Submit</button>
            </div>
        )
    }

    render() {
        return (
            
            <div className="flex">
                <div className="fixed top-28 h-[700px] right-6 z-20 w-64 transition-all duration-500 transform translate-x-full bg-sky-800 shadow-lg rounded hover:translate-x-6">
                <span className="text-white absolute top-1/2 left-3 transform -translate-y-1/2 -rotate-90 origin-left text-xs">
                    Comment
                </span>
                    <div className="px-6 py-4">
                        <h2 className="text-lg font-semibold text-white">Comment</h2>
                        <p className="text-gray-200 text-xs">Feel free to drop any ideas, questions, or suggestions in the comments below!</p>

                            {this.renderForm()}

                            <p className="text-gray-200 text-xs py-1">Previous comments</p>
                            <div className='h-[260px] overflow-y-auto overflow-x-hidden'>{this.renderComments()}</div>
                    </div>
                </div>
                <ToastContainer />
            </div> 
        )
    }
} 

export default Comment;